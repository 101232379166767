
<mat-sidenav-container class="example-container"  fixedTopGap="184" fixedBottomGap="40" >
    <mat-sidenav opened mode="side" *ngIf="showMenu" >
       &nbsp;&nbsp; <a href="javascript:void(0);" (click)="onMenuChange(false)" >hide</a>
       <br>
       <br>
       <h3 style="color: blue;">&nbsp;DOCUMENTATION&nbsp;</h3>
       <mat-button-toggle-group #group="matButtonToggleGroup" name="fontStyle2" aria-label="Font Style" vertical 
       [value]="selectedVal" (change)="onValChange(group.value)" >
       <mat-button-toggle value="G">Getting Started</mat-button-toggle>
       <mat-button-toggle value="P">Parameters</mat-button-toggle>
       <mat-button-toggle value="C">Config File Setup</mat-button-toggle>
       <mat-button-toggle value="L">License</mat-button-toggle>
       <mat-button-toggle value="S">Script Sorting Rules</mat-button-toggle>
       <mat-button-toggle value="H">Headers/Footers</mat-button-toggle>
       <mat-button-toggle value="A">Placeholders</mat-button-toggle>
       <mat-button-toggle value="K">CD/CI GitLab Example</mat-button-toggle>
       <mat-button-toggle value="T">Troubleshooting</mat-button-toggle>
       </mat-button-toggle-group>
    </mat-sidenav>
    <mat-sidenav-content>
       <div *ngIf="!showMenu">
          &nbsp;&nbsp;<a href="javascript:void(0);" (click)="onMenuChange(true)" >menu</a>
          <br>
          <br>
       </div>
       <div *ngIf="selectedVal==='G'" >
          <h1>Getting Started</h1>
          <p>
             You can download the software from our <a href="/downloads" >download</a> page. Make sure you get the correct type based on your computer and operating system. Follow the instructions on that page on how to install it.
          </p>
          <p>
             The you can obtain a trial license or purchanse a full license from the <a href="/product">buy/try</a> page.
          </p>
          <p>
             You can edit the appsettings.json file and set "RepoPath" to the path of your Git repository and "ScriptPath" to the parent directory if you SQL scripts start in a subdirectory. Or you can set both of these with commandd line arguements like: 
          </p>
          <pre><code>gitt Query --repo-path C:\myRepoPath --scripts-path someroot/myScriptDir</code></pre>
          <p>
             Below is an example where the Git Toolset can be used by a developer to list out all of the SQL files that have been changed since the start of the current development branch.
          </p>
          <pre><code>C:\Work\DBs\SampleDB> gitt Query</code></pre>
          <p>
             tables/ALM.sql
             tables/ScheduleItemTypeValues.sql
             tables/us.Machines.sql
             tables/us.xrefUserIdApps.sql
             views/vPersonnel.sql
             views/vQcQuestions.sql
             functions/fn_First.sql
             functions/fn_Last.sql
             data/AppPackageStatus.sql
             data/TopicStatus.sql
             data/excel.ExcelTemplates.sql
             scripts/FixIAndO.sql
          </p>
          <br>
          <p>
             The developer can have all of the contents of those files combined into a single release script file that can be used to apply and test the changes. 
          </p>
<pre><code>C:\Work\DBs\SampleDB> gitt Build
\Work\GitToolset_20240612T1334085038951.sql</code></pre>
          <br>
          <p>
             Usually the --start-after-tag-containing parameter can be used by a pipeline to build all of the changes since the last major release. Something like:  
          </p>
          <pre><code>[user&#64;Linux temp] $ ./gitt Build –start-after-tag-containing prod_rel_v
 /work/GitToolset_20240612T1338085034755.sql</code></pre>
       </div>
       <div *ngIf="selectedVal==='A'" >
          <h1>Header / Footer SQL Scripts Placeholders</h1>
          <p>Placeholder are variables in the header/footer scripts that are valued right before Git Toolset inserts them into the output script. </p>
          <p>See the <b>Headers/Footers</b> documentation section for more information and for examples.</p>
          <br>

          <div style="padding-left: 10%;">
             <table id='placeholder1' class='collapse listTable'>
                <caption><b>ScriptHeader.sql and ScriptFooter.sql Placeholders</b></caption>
                <tr>
                   <th  style='text-align: center'>Placeholder</th>
                   <th style='text-align: center'>Value</th>
                </tr>
                <tr>
                   <td   style='text-align: center'>%p</td>
                   <td>Project name</td>
                </tr>
                <tr>
                   <td   style='text-align: center'>%a</td>
                   <td>Project author</td>
                </tr>
                <tr>
                   <td   style='text-align: center'>%b</td>
                   <td>Current branch</td>
                </tr>
                <tr>
                   <td   style='text-align: center'>%r</td>
                   <td>Path to Git repo</td>
                </tr>
                <tr>
                   <td   style='text-align: center'>%S</td>
                   <td>Subdirectory to SQL script root with in repo</td>
                </tr>
                <tr>
                   <td   style='text-align: center'>%o</td>
                   <td>Git Toolset's output full path</td>
                </tr>
                <tr>
                   <td   style='text-align: center'>%f</td>
                   <td>Git Toolset's output file name</td>
                </tr>
                <tr>
                   <td   style='text-align: center'>%c</td>
                   <td>Included files count</td>
                </tr>
                <tr>
                   <td   style='text-align: center'>%t</td>
                   <td>Current time in UTC</td>
                </tr>
                <tr>
                   <td   style='text-align: center'>%T</td>
                   <td>Current local time</td>
                </tr>
                <tr>
                   <td   style='text-align: center'>%l</td>
                   <td>List of the included script files</td>
                </tr>
                <tr>
                   <td   style='text-align: center'>%s</td>
                   <td>Search criteria for which script files to include</td>
                </tr>
             </table>
             <br>
             <br>
             <br>
             <table id='placeholder1' class='collapse listTable'>
                <caption><b>FileHeader.sql and FileFooter.sql Placeholders</b></caption>
                <tr>
                   <th  style='text-align: center'>Placeholder</th>
                   <th style='text-align: center'>Value</th>
                </tr>
                <tr>
                   <td   style='text-align: center'>%p</td>
                   <td>File script's full path</td>
                </tr>
                <tr>
                   <td   style='text-align: center'>%f</td>
                   <td>File script's file name</td>
                </tr>
                <tr>
                   <td   style='text-align: center'>%cs</td>
                   <td>The file script's last included Git commit Sha</td>
                </tr>
                <tr>
                   <td   style='text-align: center'>%ct</td>
                   <td>The file script's last included Git commit timestamp</td>
                </tr>
                <tr>
                   <td   style='text-align: center'>%ca</td>
                   <td>The file script's last included Git commit author</td>
                </tr>
                <tr>
                   <td   style='text-align: center'>%cm</td>
                   <td>The file script's last included Git commit message</td>
                </tr>
                <tr>
                   <td   style='text-align: center'>%cn</td>
                   <td>The file script's last included Git commit note</td>
                </tr>
             </table>
             <br>
             <br>
             <br>
          </div>
       </div>
       <div *ngIf="selectedVal==='H'" >
          <h1>Header / Footer SQL Scripts</h1>
          <p>You have four SQL files that you can use to add SQL scripts to your generated Git Toolset file.</p>
          <p>They can contain placeholder that will be valued at generation time.</p>
          <p>These are written in the SQL compatable with your database.</p>
          <p>Some or all can be left blank if you don't want to use them.</p>
          <p>placeholders can be set in the appsettings.json file or from a command line parameter.</p>
          <ul>
             <li>
                <p><b>ScriptHeader.sql</b> - Added to the start of the generated Git Toolset just once.</p>
                <p>Acts as a header to your Git Toolset file.</p>
                Example:
                <pre><code>/* 
       Output File: %o
      Project Name: %p
            Author: %a
            Branch: %b
              Time: %t

   Search Criteria: 
                     %s
   
        File Count: %c
         Files: 
            %l
                 
*/</code></pre>
                <p>So it will replace the %o with the output file name, %p with your project name, etc.</p>
                <p> %l will be valued with all of the SQL script file names included in the Git Toolset.</p>
                Example after valued:
                <pre><code>/* 
       Output File: c:\output\OneScript_20240602T1644.sql
      Project Name: First Project
            Author: Mark Smith
            Branch: 4.0
              Time: 06/02/2024 21:44:32

   Search Criteria: 
                    Branch: 4.0
            Start Criteria: Branch First Commit
              End Criteria: Nothing specified, so HEAD
                  Criteria: Commits between 05/26/2024 00:22:42 -05:00 and 06/02/2024 21:26:54 -05:00



   Files: Count 12
         File Name                            Commiter              Commite Time                 SHA
         data/TopicStatus.sql                Jen-at-002462198889   06/01/2024 18:11:45 -04:00   c04cc3f 
         functions/fn_First.sql              Sam-at-774864714866   06/01/2024 18:11:45 -04:00   c04cc3f 
         tables/ALM.sql                      Jim-at-421564710321   06/01/2024 18:11:45 -04:00   c04cc3f 
         tables/ScheduleItemTypeValues.sql   Jim-at-421564710321   05/27/2024 18:45:25 -04:00   afa025d 
         tables/us.Machines.sql              Sam-at-774864714866   06/01/2024 18:11:45 -04:00   c04cc3f 
         views/vPersonnel.sql                Jen-at-002462198889   06/01/2024 18:11:45 -04:00   c04cc3f 
         views/vQcQuestions.sql              Jim-at-421564710321   06/01/2024 18:13:25 -04:00   7eaf594 
         data/excel.ExcelTemplates.sql       Sam-at-774864714866   06/01/2024 18:11:45 -04:00   c04cc3f 
         scripts/FixIAndO.sql                Jim-at-421564710321   06/01/2024 18:11:45 -04:00   c04cc3f 
         tables/us.xrefUserIdApps.sql        Sam-at-774864714866   06/01/2024 18:11:45 -04:00   c04cc3f 
         data/AppPackageStatus.sql           Jen-at-002462198889   06/01/2024 18:11:45 -04:00   c04cc3f 
         functions/fn_Last.sql               Jim-at-421564710321   06/01/2024 18:11:45 -04:00   c04cc3f 
*/</code></pre>
             </li>
             <br>
             <br>
             <li>
                <p><b>FileHeader.sql</b> - Will be added before each included script file.</p>
                <p>Uses a list of placeholders specific to files.</p>
                Example:
                <pre><code>
   Print '   Start Processing: %p'
   Print '   Last Commit Time: %ct'
   Print ' Last Commit Author: %ca'
   Print 'Last Commit Message: %cm'
   Print '    Last Commit SHA: %cs'</code></pre>
                Example after valued:
                <pre><code>
   Print '   Start Processing: tables/Surveys.sql'
   Print '   Last Commit Time: 11/27/2023 21:26:54 -05:00'
   Print ' Last Commit Author: Jim-at-421586710321'
   Print 'Last Commit Message: (Jira:19492): Survey score and status'
   Print '    Last Commit SHA: 042aa4ab554a8ead8cf2bfdeba3913b9057bc44d'</code></pre>
             </li>
             <br>
             <br>            
             <li>
                <p><b>FileFooter.sql</b> - Will be added after each included script file.</p>
                Example:
                <pre><code>GO
Print 'Finished processing: %p'</code></pre>
                Example after valued:
<pre><code>GO
Print 'Finished processing: tables/SurveyStatus.sql'</code></pre>
             </li>
             <br>
             <li>
                <p><b>ScriptFooter.sql</b> - Will be added at the end of the generated Git Toolset file.</p>
                Example:
                <pre><code>GO
Print 'Finished Creating Script %o'</code></pre>
   Example after valued:<br><pre><code>GO
Print 'Finished Creating Script c:\output\OneScript_20231211T1644.sql'</code></pre>
             </li>
             <br>
             <br>
             <p>These headers and footers add more documentation directly to the Git Toolset file.</p>
             <p>One of the big benefits from adding these headers and footers is that it becomes very easy to find the cause of any build errors when the script is applied to a database.</p>
             <div style="margin-left: 10%;">
                <img src="BuildOutput.png" alt="Build Output" />
                <br>
                <span styple="font-size: small; color: lightgray" >Output when a Git Toolset with an error is applied to a database.</span>
             </div>
             <br>
             <p>In the appsettings.json file you can also optionally set the default values for many other parameters.</p>
          </ul>
       </div>
       <div *ngIf="selectedVal==='L'" >
          <h1>Install or Update a License Key for gitt</h1>
          <p>This topic describes how to install or update a licesnse key for Git Toolset Command Line Interface (gitt).</p>
          <p>After you obtain a license key by activating a trial or purchasing a liceense key, you can install or update it in one of three ways.</p>
          <ul>
             <li>
                <b>Add at the command line using gitt with the command AddLicense</b>
                <p>You must have rights to write to the appsettings.json file found in the gitt install directory. Or on Linux prefix the command with <code>sudo</code>.</p>
                <p>Add or update the license key using the AddLicense command with your license key. Example:</p>
                <pre><code>$ gitt AddLicense .e29d6963f43a4416bb74860ac006bc84.725760.1.1.a555f.</code></pre>
             </li>
             <li>
                <b>Pass the license key as a parameter each time you run gitt.</b>
                <p>
                   You do not need rights to change appsettings.json when doing it this way.
                </p>
<pre><code>$ gitt --License .e29d6963f43a4416bb74860ac006bc84.725760.1.1.a555f.  ...</code></pre>
<pre><code>$ gitt -l .e29d6963f43a4416bb74860ac006bc84.725760.1.1.a555f.  ...</code></pre>
             </li>
             <li>
                <b>Add the license key to appsettings.json found in the gitt install folder.</b>
                <p>You must have rights to write to the appsettings.json file found in the gitt install directory.</p>
                <p>
                   It is best to make a backup of appsettings.json before editing.
                </p>
<pre><code>&#123;
  "License": ".e29d6963f43a4416bb74860ac006bc84.725760.1.1.a555f.",
    ...</code></pre>
             </li>
          </ul>
       </div>
       <div *ngIf="selectedVal==='K'" >
          <app-git-lab></app-git-lab>
       </div>
       <div *ngIf="selectedVal==='C'" >
          <h1>Configuration Settings for gitt</h1>
          <p>In the gitt install directory there is a configuration file appsettings.json. This file needs to have variables set depending on your environment. You can also set defaults for parameters in this file.</p>
          <p>Here are the variables that must be setup after installing gitt.</p>
          <ul>
             <li>
                <p><b>RepoPath</b> - The path to where your Git repository is cloned to.</p>
                Examples:
                <ul>
                   <li><pre><code>"RepoPath": "C:\\Work\\Projects\\MyProject",</code></pre></li>
                   <li><pre><code>"RepoPath": "C:\\Work\\DBs\\SampleDB",</code></pre></li>
                   <li><pre><code>"RepoPath": "/work/code/myproject",</code></pre></li>
                </ul>
             </li>
             <br>
             <li>
                <p><b>ScriptPath</b> - The path within your repository when SQL script folders start.</p>
                Examples:
                <ul>
                   <li><pre><code>"ScriptPath": "",</code></pre></li>
                   <li><pre><code>"ScriptPath": "\SQLScripts",</code></pre></li>
                   <li><pre><code>"ScriptPath": "db/scripts",</code></pre></li>
                </ul>
             </li>
             <br>
             <br>
             <li>
                <p><b>SystemFolder</b> - Release Scripts' system folder. The folder where gitt can find header and footer SQL files.</p>
                Examples:
                <ul>
                   <li><pre><code>"SystemFolder": "ReleaseScript\\SQLServer",</code></pre></li>
                   <li><pre><code>"SystemFolder": "releasescript/oracle",</code></pre></li>
                   <li><pre><code>"SystemFolder": "/opt/pipeline/releasescript",</code></pre></li>
                </ul>
             </li>
             <br>
             <li>
                <p><b>ExcludePathsContaining</b> - An array of paths to exclude from gitt processing. So even if files under them have change commits in Git, they will be ignored. </p>
                Examples:
                <ul>
                   <li><pre><code>"ExcludePathsContaining": ["Migrations", "ReleaseScript", "Rollbacks"],</code></pre></li>
                   <li><pre><code>"ExcludePathsContaining": ["system/migrations", "hotfix"],</code></pre></li>
                </ul>
             </li>
             <br>
             <li>
                <p><b>PlaceHolders</b> - Release Scripts' header and footer scripts can contain placeholders such as %p for project name, %a for author, or %t for current time. This is a set of Key / Value pairs. See Header/Footer section of documentation for more information.</p>
                Examples:
                <ul>
                   <li><pre><code>"PlaceHolders": &#123;"%p": "Git Toolset", "%a": "Digital Tools, LLC"&#125;,</code></pre></li>
                   <li><pre><code>"PlaceHolders": &#123;"%p": "MyPoject", "%a": "ABC Dev Group"&#125;,</code></pre></li>
                </ul>
             </li>
             <br>
             <li>
                <p><b>DatabaseQuoteChar</b> - This is used to remove the database quote char from placeholders. For example MySql and MS SQL Server use ', and Oracle uses ".</p>
                Examples:
                <ul>
                   <li><pre><code>"DatabaseQuoteChar": "'",</code></pre></li>
                   <li><pre><code>"DatabaseQuoteChar": "\"",</code></pre></li>
                </ul>
             </li>
             <br>
             <p>In the appsettings.json file you can also optionally set the default values for many other parameters.</p>
             <br>
             <br>
             <br>
          </ul>
       </div>
       <div *ngIf="selectedVal==='P'" >
          <app-params></app-params>
       </div>
       <div *ngIf="selectedVal==='S'">
         <app-sort-orders></app-sort-orders>
       </div>

       <div *ngIf="selectedVal==='T'" >
          <h1>Troubleshooting</h1>
          <ul>
             <li>
                <p><b>Debug</b></p>
                <p>     
                   Note: you can pass in the -d or –Debug parameter to see detailed debug logging.
                </p>
                <br >
             </li>
             <li>
                <p><b>Wrong computer architecture / operating system errors</b></p>
                <p>
                   Cannot execute binary file: Exec format error 
                   The image file … is valid, but is for a machine type other than the current machine.
                </p>
                <br >
             </li>
             <li>
                <p><b>ERROR: License failed: The license key is empty</b></p>
                <p>
                   If passing in the license as a parameter. Make sure there is a space between the "-l" and the first "." of the license key.
                </p>
                <br >
             </li>
             <li>
                <p><b>Looks like it isn't including all of the .sql file you think it should. </b></p>
                <p>
                   The git clone command has an option named <b>depth</b>. 
                </p>
                <p>    This controls how much history it included in the clone. The default is all history. 
                </p>
                <p>Some CI/CD pipelines set this to something less.</p>
                <p> In this documentation we have a GitLab tab with a sample .gitlab-ci.yml file.
                </p>
                <p>In there it sets an environment variable to 0 to get all of the historn. This overrides GitLabs default of 20.</p>
                <pre><code><span class="key">variables</span>:
&nbsp;&nbsp;<span class="key">GIT_DEPTH</span>: <span class="string">0</span>&nbsp;&nbsp; <span class="comment"># important to allow gitt to find start commit in history</span></code></pre>
     <p>You don't have to do all, your setting would depend on your commit activity and length of time between releases to production.</p>
   </li>
             <li>
                <p><b>Bad text in Ouput File</b></p>
                <p>
                   You have included a binary file in your selection if the output file has content text like below.
                </p>
                <p style="padding-left: 25px;">
                   ëNÄ÷1|╟Líëå-ï
                </p>
                <p style="padding-left: 25px;">
                   ¿þì
                </p>
                <p style="padding-left: 25px;">
                   ‹ÃHƒÄ [ÃÌ
                </p>
             </li>
          </ul>
       </div>
    </mat-sidenav-content>
 </mat-sidenav-container>