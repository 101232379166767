import { Component } from '@angular/core';
import { AppService } from '../../services/app.service';
import { LogService } from '../../infrastructure/logging/log.service';
import { ProductPage } from '../../models/ProductPage';
import { Router } from '@angular/router';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent {

  product: ProductPage;
  quantity = 1;

  constructor(public appService: AppService,
    private logger: LogService,  
    public router: Router
  ) {
    logger.info("in ProductComponent");
    localStorage.setItem('target', '');
    logger.info("Calling Get Product 1");
    appService.getProduct(1).subscribe({
      next: (product: ProductPage) => {
        logger.info("in getproduct next");
        this.product = product;
      },
    error: (err) => { logger.info('HTTP Error', err)},
    complete: () => logger.info('HTTP request completed.')
  });

  }
  
  buy() : void {
    var url = "checkout/buy/" + this.quantity.toString();
    localStorage.setItem("target", url)
    this.router.navigate([url]);
     
  }

  trial() : void {
    var url = "checkout/trial/1";
    localStorage.setItem("target", url)
    this.router.navigate([url]);
     
  }



}
