import { HttpClient } from '@angular/common/http';;
import { Observable, of, throwError } from 'rxjs';
import { map, catchError  } from 'rxjs/operators';
import { from } from 'rxjs';
import { LogEntry } from './log.service';
import { LogLevel } from './models/LogLevel';

// ****************************************************
// Log Publisher Abstract Class
// NOTE: This class must be located BEFORE
//       all those that extend this class
// ****************************************************
export abstract class LogPublisher {
    location: string = "";
    level: LogLevel = LogLevel.Debug;
    
    abstract log(record: LogEntry): void
    abstract clear(): void;
}

// ****************************************************
// Console Logging Class
// ****************************************************
export class LogConsole extends LogPublisher {
    log(entry: LogEntry): void {
        // Log to console
        if(entry)
        {
            console.log(entry.buildLogString());
        }
    }

    clear(): void {
        console.clear();
    }
}

// ****************************************************
// Local Storage Logging Class
// ****************************************************
export class LogLocalStorage extends LogPublisher {
    constructor() {
        // Must call super() from derived classes
        super();
        // Set location
        this.location = 'logging';
    }

    // Append log entry to local storage
    log(entry: LogEntry) : void {
        let values: LogEntry[];

        try {
            // Retrieve previous values from local storage
            var oldValues = localStorage.getItem(this.location);

            values = oldValues == null ? [] : JSON.parse(oldValues);
            // Add new log entry to array
            let len: number = values.push(entry);

            if(len > 10)           
                values = values.splice(len - 10);
            // Store array into local storage
            localStorage.setItem(this.location, JSON.stringify(values));
        } catch (ex) {
            // Display error in console
            console.log(ex);
        }

    }

    // Clear all log entries from local storage
    clear(): void {
        localStorage.removeItem(this.location);
    }
}

// ****************************************************
// Logging Web API Class
// ****************************************************
export class LogWebApi extends LogPublisher {

    userId: string;

    constructor(private http: HttpClient) {
        // Must call super() from derived classes
        super();
        // Set location
    
        this.location = 'Infrastructure/Log';
        this.userId = localStorage.getItem('userId') || 'anonymous';
        
    }

    // **************
    // Public Methods
    // **************

    // Add log entry to back end data store
    log(entry: LogEntry): void {
        entry.updatedBy = this.userId;
        this.http.post<LogEntry>(this.location, entry)
            .pipe(map(response => response),
            catchError(this.handleErrors))
            .subscribe(
               (ret) => { return of(true);},
               (err) => {alert(err); return of(false);}
            );
    }

    // Clear all log entries from local storage
    clear(): void {
        // TODO: Call Web API to clear all values
    }

    // ***************
    // Private Methods
    // ***************
    private handleErrors(error: any): Observable<any> {
       
        let errors: string[] = [];
        let msg: string;

        msg = 'Status: ' + error.status;
        msg += ' - Status Text: ' + error.statusText;
        if (error) {
            msg += ' - Exception Message: ' + error.exceptionMessage;
        }
        errors.push(msg);

        console.error('An error occurred', errors);

        return throwError(errors);
    }
}