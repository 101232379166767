import { Component, inject, OnInit } from '@angular/core';
import { LogService } from './infrastructure/logging/log.service';
import { HeaderComponent } from '../app/components/header/header.component';

@Component({
  selector: 'app-root',
  standalone: false,
  //imports: [HeaderComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {
  title = 'Gitt Toolset';
 logger = inject(LogService);
 
 constructor() {
 
 }
 ngOnInit(): void {
 }


}
