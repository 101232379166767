import { Component, OnInit, signal } from '@angular/core';
import { AuthService } from '../../infrastructure/login/auth.service';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
//import { FlexBoxModule } from 'flexbox';
@Component({
  selector: 'app-header',
  //standalone: false,
  //imports: [NgIf, AsyncPipe, MatMenuModule, MatButtonToggleModule, MatButtonModule, CommonModule],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent implements OnInit {

// see: https://github.com/DevBySeb/DevBySeb/blob/main/src/app/header/header.component.ts
  isLoggedIn = false;  
  isMenuOpen = false;
  isSubMenuOpen = false;

  constructor(public authService: AuthService,
    public router: Router
  )
  {
  
    this.authService.isLoggedIn$.subscribe({
      next(value): void {
        console.log('Header sees authService.isLoggedIn$ Changed');
        console.log('Current Is Logged In Value: ', value);
       // this.isLoggedIn = value;
       // console.log('Current isLoggedIn Value: ', this.authService.isLoggedIn);
      
      },
      error(msg): void {
        console.log('Error Getting Is Logged In: ', msg);
      }
    });
  
  }
  ngOnInit(): void {
    this.router.events
    .pipe(filter(event => event instanceof NavigationEnd))
    .subscribe((event: NavigationEnd) => {
        // Do something with the new route
        console.log('Current route:', event.url);
        this.isMenuOpen = false;
        this.isSubMenuOpen = false;

    });
  }

  

  toggleMenu(): void {
    if(this.isMenuOpen || this.isSubMenuOpen)
    {
      this.isMenuOpen = false;
      this.isSubMenuOpen = false;
    }
    else 
    {
      this.isMenuOpen = true;
    }
}




  // these only come into play on a small screen.
  onSupport() {
    this.isMenuOpen = false;
    this.isSubMenuOpen = true;

  }


onLogoutClick() {
  console.log("Logout Clicked");
  this.authService.clearAuth();
  this.router.navigate(['/home']);
  //define your logic
}


onLicense() {
  console.log("License Clicked");
  localStorage.setItem('target', 'licenses');
  this.router.navigate(['/licenses']);
  //define your logic
}



}
