import { AfterViewInit, Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { LogService } from '../../infrastructure/logging/log.service';
import { AuthService } from '../../infrastructure/login/auth.service';
import { AppService } from '../../services/app.service';
import { License } from '../../models/License';
import { BaseListComponent } from '../../infrastructure/base-list/base-list.component';
import { DataSource } from '@angular/cdk/collections';

@Component({
  selector: 'app-licenses',
  templateUrl: './licenses.component.html',
  styleUrls: ['./licenses.component.scss']
})
export class LicensesComponent extends BaseListComponent
{
  public dataSource: MatTableDataSource<License>;

  constructor(public appService: AppService,
    private logger: LogService
  ) {
    super(appService);

    this.displayedColumns = ['licenseKey', 'trialEnd', 'isActivated'];

    logger.info("Calling Get Licenses");
    appService.getLicenses().subscribe({
      next: (lics) => {
        logger.info("in getLicenses next");
    
        this.dataSource = new MatTableDataSource<License>(lics);
        this.dataSource.sort = this.sort;
      },
    error: (err) => { logger.info('HTTP Error', err)},
    complete: () => logger.info('HTTP request completed.')
  });

  }

  public getKey(lic: License) : string {
    
    return lic.licenseKey;
  }

  public onClipboardCopyImage(licKey: string): void {
    console.log('onClipboardCopyImage');
    navigator.clipboard.writeText(licKey).catch(() => {
      console.error("Unable to copy text");
    });

    this.headMessage = licKey + ' copied';
    console.log(this.headMessage);
   }

  public onClipboardCopy(successful: boolean, licKey: string): void {
    console.log('onClipboardCopy');

    console.log(successful);
    if(successful)
    {
      
    this.headMessage = licKey + ' copied';
    console.log(this.headMessage);
    }
    else   
    {
        this.headMessage = "Copy to clipboard did not work."; 
        console.log(this.headMessage);
        alert(this.headMessage);
    }
  }

}

