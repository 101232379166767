import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LogService } from '../../infrastructure/logging/log.service';
import { AppService } from '../../services/app.service';
import { TextResponse } from '../../models/TextResponse';

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.scss']
})
export class SuccessComponent {

  params = '';
  constructor(public appService: AppService,
    private logger: LogService,
    activatedRoute: ActivatedRoute,
    public router: Router
  ) {


    logger.info("starting success component");
   
    activatedRoute.queryParams.subscribe((params) => {
      const orderId = params['orderId'];
      if(!orderId)
      {
        return;
      }

      var status = localStorage.getItem(orderId);
      if(status)
      {
        if(status == 'done')
        {
          this.router.navigate(["/licenses"]);       
          return;
        }
      }

      this.success(orderId, params["sessionId"]);  
    });
    
  }

  success(orderId: string, sessionId: string): void {
    
    this.logger.info("orderId: " + orderId + " sessionId: " + sessionId);
    localStorage.setItem(orderId, 'try');
    this.appService.success(orderId, sessionId).subscribe({
      next: (result: TextResponse) => {
        localStorage.setItem(orderId, 'done');
        this.logger.info("in success");
        this.logger.info("result: " + result.text);
        alert(result.text);
        this.router.navigate(["/licenses"]);
      },
    error: (err) => { this.logger.info('HTTP Error', err)},
    complete: () => this.logger.info('HTTP request completed.')
  });


  }



}
