import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Validators, FormBuilder } from '@angular/forms';
import { AuthService } from '../login/auth.service';
import { of } from 'rxjs';
import { ChangeRequestResponse } from '../shared/changeRequestResponse';
import { LogService } from '../logging/log.service';
import { BaseComponent } from '../base/base.component';
import { SettingsService } from '../app-settings/settings.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent extends BaseComponent {

  hide = true;
  hideOld = true;
  email: string;

  constructor(
    activatedRoute: ActivatedRoute,
    router: Router,
    fb: FormBuilder,
    settings: SettingsService,
    logger: LogService,
    public authService: AuthService
  ) {
    super(activatedRoute, router, fb, settings, logger);

    this.form = this.fb.group({
      password: ['', Validators.required],
      newPassword: ['', Validators.required]

    });

    this.email = authService.user.email;
  }


  override onSave(): void {

    this.authService.changePassword(this.email, this.form.get('oldPassword').value, this.form.get('password').value)
      .subscribe(
        (res: ChangeRequestResponse) => {

          if (res.succeeded) {
            this.router.navigate(['/home']);
          } else {
            this.errorMessage = res.message;
            this.logger.info('Changing password failed: ' + res.message);
          }
        },
        err => {       this.EndLoading();

          this.logger.error(err);
          this.errorMessage = err;
          of([]);
        },
        () => {
          this.EndLoading();
          this.logger.info('completed');
        }
      );
  }
}

