import { Component } from '@angular/core';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  standalone: false,
  styleUrls: ['./overview.component.scss']
})
export class OverviewComponent {

}
