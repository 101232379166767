import { NgModule, OnInit } from '@angular/core';
import { LogPublisher } from '../log-publishers';
import { LogEntry } from '../log.service';
import { Observable, of } from 'rxjs';
import { MatDialog, MatDialogModule, MAT_DIALOG_DEFAULT_OPTIONS, MatDialogRef } from '@angular/material/dialog';
import { DialogComponent } from '../../controls/dialog.component';
import { LogLevel } from '../models/LogLevel';


@NgModule()
export class ErrorDialogModule extends LogPublisher {

  constructor(private dialog: MatDialog) {super(); }

  log(record: LogEntry): void {
    console.log(record.message);
    

    console.log('Starting ErrorDialogModule.log()');

    setTimeout(() => {

      const dialogRef = this.dialog.open(DialogComponent, {
        data: { title: 'Application Error', message: record.message, content: record.extraInfo },
        hasBackdrop: true, disableClose: false, autoFocus: true

      });

      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
      });

    }, 500);

  }

  clear(): void {

  }

}
