
<page-header [loading]='(authService.loading$ | async) || false' [errorMessage]='errorMessage' ></page-header>

<div class='signin-content'>
  <mat-card>
    <mat-card-content>
      <h2>{{message}}</h2>


      <h3>{{instructions}}</h3>

      <form [formGroup]='form'>

        <mat-form-field class='full-width-input'>

          <input matInput placeholder='Email' type='email' formControlName='email' required />
          <mat-icon matSuffix>email</mat-icon>

          <mat-error *ngIf="hasError('email')">
            Please enter your email address
          </mat-error>
        </mat-form-field>
        <br>
        <mat-form-field class='full-width-input'>
          <input matInput [type]="hide ? 'password' : 'text'"  placeholder='Password' formControlName='password'
            required />
     <button mat-icon-button
     matSuffix
     (click)="hide = !hide"
     [attr.aria-label]="'Hide password'"
     [attr.aria-pressed]="hide">
          <mat-icon matSuffix  (click)='hide = !hide'>{{hide ? 'visibility' : 'visibility_off'}}</mat-icon>
        </button>
        <mat-error *ngIf="hasError('password')">
            Please enter your password
          </mat-error>
        </mat-form-field>
        <br>
        <br>
        
        <button mat-raised-button color='primary' (click)='onSave()' [disabled]='form.invalid' type='button'>Login</button>
      </form>
      <br>
      <br>
      <p *ngIf="showLinkMessage" ><b>Make sure you activated the account by clicking the link in the confirmation email you were be sent. </b></p>

      <br>
   
      <a  href="javascript:void(0);"  (click)='register()' routerLinkActive='active' class='alc'><strong>Create a New Account?</strong></a>
      <br>
      <br>
      <a href="javascript:void(0);" (click)='forgot()' routerLinkActive='active' class='alc'><strong>Forgot Password</strong></a>
          
      <br>
      <br>
      <a href="javascript:void(0);" (click)='resend()' routerLinkActive='active' class='alc'><strong>Resend Confirmation Email</strong></a>
     
      <br>

    </mat-card-content>
  </mat-card>
</div>
