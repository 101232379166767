

<page-header title="Licenses"  [loading]='(appService.loading$ | async) || false' [errorMessage]='errorMessage' ></page-header>

<h3>{{headMessage}}</h3>

<div style="text-align:right;padding-right: 15px;">
<button mat-raised-button color="primary" routerLink="/downloads" routerLinkActive="active">Downloads</button>
</div>   
<br>
<br>

<table mat-table [dataSource]='dataSource' matSort class='mat-elevation-z8 list'>

    <ng-container  matColumnDef='copy'>
        <th mat-header-cell mat-sort-header *matHeaderCellDef></th>
        <td mat-cell *matCellDef='let element'> <button 
            [cdkCopyToClipboard]='getKey(element)'
            (cdkCopyToClipboardCopied)="onClipboardCopy($event, getKey(element))"
             matTooltip="Copy to License Key to Clipboard"
          >Copy</button> 
        
        </td>
      </ng-container>
    


  <ng-container  matColumnDef='licenseKey'>
    <th mat-header-cell mat-sort-header *matHeaderCellDef> License Key </th>
    <td mat-cell *matCellDef='let element'> {{element.licenseKey}} &nbsp;        
      <a href="javascript:void(0);" (click)="onClipboardCopyImage(getKey(element))"><img src="Copy.png" alt="Copy" /></a>
    </td>
  </ng-container>

  <ng-container matColumnDef='trialEnd'>
    <th mat-header-cell mat-sort-header *matHeaderCellDef> Trial End </th>
    <td mat-cell *matCellDef='let element'> {{element.trialEnd  | date : 'MM/dd/y' }}  </td>
  </ng-container>

  <ng-container matColumnDef='isActivated'>
    <th mat-header-cell mat-sort-header *matHeaderCellDef> Is Active </th>
    
    <td mat-cell *matCellDef='let element'> 
        <mat-checkbox (click)="$event.stopPropagation()"
        [checked]=element.isActivated disabled >
        </mat-checkbox>

         </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef='displayedColumns'></tr>
  <tr mat-row *matRowDef='let row; columns: displayedColumns;'></tr>
</table>
<br>
<br>
<br>

<button mat-raised-button color="primary" routerLink="/downloads" routerLinkActive="active">Downloads</button>
    