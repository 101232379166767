<page-header [loading]='(authService.loading$ | async) || false' [errorMessage]='errorMessage' ></page-header>

<div class='signin-content'>
  <mat-card>
    <mat-card-header>
        <h3>Reset Password for {{email}}</h3>
    </mat-card-header>
    <mat-card-content>
      <form [formGroup]='form'>
        <mat-form-field class='full-width-input'>
          
          <textarea matInput placeholder='Token Sent to You in Email' type='text'
                 formControlName='token' required></textarea>
                 <mat-icon matSuffix>token</mat-icon>
                 
           <mat-error *ngIf="hasError('token')">
            Please enter the toke that was emailed to you.
          </mat-error>
        </mat-form-field>
       
    <br>
    <br>
        <mat-form-field class='full-width-input'>
          <input matInput placeholder='New Password' [type]="hide ? 'password' : 'text'" 
                 formControlName='password' required />
                 <button mat-icon-button
                 matSuffix
                 (click)="hide = !hide"
                 [attr.aria-label]="'Hide password'"
                 [attr.aria-pressed]="hide">
                      <mat-icon matSuffix  (click)='hide = !hide'>{{hide ? 'visibility' : 'visibility_off'}}</mat-icon>
                    </button>
        <mat-error *ngIf="hasError('password')">
            Please enter your new password
          </mat-error>
        </mat-form-field>
        <br>
        <br>
        <button mat-raised-button color='primary' [disabled]='form.invalid' (click)='onSave()' type='button'>Reset</button>
      </form>
      <br>
      
    </mat-card-content>
  </mat-card>
</div>
