import { Injectable, OnInit, PLATFORM_ID, Inject } from '@angular/core';
import { Observable, pipe, of, throwError, from } from 'rxjs';
import { map, catchError, finalize } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AppSettings, IDictionary } from './models/appsettings';
import { AppSetting } from './models/appsetting';
import { BaseServiceService } from '../shared/base-service.service';
import { LogService } from '../logging/log.service';
import { SettingsService } from './settings.service';
import { ChangeRequestResponse } from '../shared/changeRequestResponse';
import { GenericService } from '../services/generic.service';
import { IUrlCrud } from '../interfaces/IUrlCrud';


@Injectable({
    providedIn: 'root'
})
export class AppSettingsService extends GenericService<AppSetting> {
    //public override settings!: SettingsService;

    
    constructor(public override http: HttpClient, public override settings: SettingsService, public override logger: LogService) {
        super(http, settings, logger,
            {
                get: 'infrastructure/Get/',
                getList: 'infrastructure/GetList',
                save: 'infrastructure/Save',
                delete: 'infrastructure/Delete/'
            } as IUrlCrud
        );

    }

    /*
        getList(): Observable<AppSetting[]> {
            const url = this.settings.get('BASEURL') + 'infrastructure/AppSettings'
            this.startingCall(url, null);
            // WinAuth
            //let options = new RequestOptions({ withCredentials: true});
            return this.http.get<AppSetting[]>(url).pipe(
                catchError((err) => this.handleError(err) ),
                finalize(() => this.endCall())
            );
        }
       get(id: string): Observable<AppSetting> {
            const url = this.settings.get('BASEURL') + 'infrastructure/AppSetting/' + id
            this.startingCall(url, id);
            return this.http.get<AppSetting>(url).pipe(
                catchError((err) => this.handleError(err) ),
                finalize(() => this.endCall())
            );
        }

        save(appSetting: AppSetting): Observable<ChangeRequestResponse> {
       const url = this.settings.get('BASEURL') + 'infrastructure/save';

       this.startingCall(url, appSetting);
        return this.http.post<ChangeRequestResponse>(url, appSetting)
        .pipe(catchError(err => this.handleError(err)),
        finalize(() => this.endCall())
        );

        }

        delete(id: string): Observable<ChangeRequestResponse> {
            const url = this.settings.get('BASEURL') + 'infrastructure/delete/';
            this.startingCall(url, id);
            return this.http.get<ChangeRequestResponse>(url + id)
            .pipe(
                catchError(err => this.handleError(err)),
                finalize(() => this.endCall())
            );

        }
    */

    sendEmail(emailSettings: any): Observable<ChangeRequestResponse> {
        const url = this.settings.get('BASEURL') + 'infrastructure/SendEmail';

        this.startingCall(url, emailSettings);
        return this.http.post<ChangeRequestResponse>(url, emailSettings)
            .pipe(catchError(err => this.handleError(err)),
                finalize(() => this.endCall())
            );

    }
}
