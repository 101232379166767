import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { Log, LogsWithCount } from './models/Log';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { AppSettingsService } from '../app-settings/appsettings.service';
import { catchError, finalize } from 'rxjs/operators';
import { LogWebapiService } from './log-webapi.service';
import { Injectable } from '@angular/core';
import { LogService } from './log.service';
import { BaseServiceService } from '../shared/base-service.service';
import { SettingsService } from '../app-settings/settings.service';

@Injectable()
export class LogListDataSource extends BaseServiceService
  implements DataSource<Log> {
  private dataSubject = new BehaviorSubject<Log[]>([]);
  public count: number;

  constructor(
    private appsettingsService: AppSettingsService,
    logger: LogService,
    settings: SettingsService,
    private logWebApiService: LogWebapiService
  ) {
    super(logger, settings);

    logger.info('constructor for LogListDataSource');
    logger.info('loading$=' + this.loadingSubject.value);
  }

  connect(collectionViewer: CollectionViewer): Observable<Log[]> {
    return this.dataSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.dataSubject.complete();
    this.loadingSubject.complete();
  }

  loadData(
    sortColumn: string = 'id',
    sortDirection: string = 'asc',
    pageNumber: number = 0,
    pageSize: number = 30,
    logLevel: number = 0,
    updatedByFilter: string = ''
  ) {
    this.logger.info('sortColumn: ' + sortColumn);

    this.logger.info('Going to get Log data from the server.');
    this.loadingSubject.next(true);

    this.logWebApiService
      .getLogs(
        sortColumn,
        sortDirection,
        pageNumber,
        pageSize,
        logLevel,
        updatedByFilter
      )
      .pipe(
        catchError(err => this.handleError(err)),
        finalize(() => {
            this.endCall();
            this.loadingSubject.next(false);
        })
      )
      .subscribe((logsWithCount: LogsWithCount) => {
        this.count = logsWithCount.count;
        this.logger.info(`${this.count} items loaded`);
        this.dataSubject.next(logsWithCount.logs);
      });
  }
}
