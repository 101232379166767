import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Validators, FormBuilder, FormGroup, AbstractControl } from '@angular/forms';
import { AuthService } from '../login/auth.service';
import { User } from '../login/user';
import { of } from 'rxjs';
import { ChangeRequestResponse } from '../shared/changeRequestResponse';
import { LogService } from '../logging/log.service';
import { BaseComponent } from '../base/base.component';
import { SettingsService } from '../app-settings/settings.service';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent extends BaseComponent {
  hide = true;
  showToken = false;
  private code: string;
  formToken: FormGroup;

  constructor(
    private activatedRoute: ActivatedRoute,
    router: Router,
    fb: FormBuilder,
    settings: SettingsService,
    logger: LogService,
    public authService: AuthService
  ) {
    super(activatedRoute, router, fb, settings, logger);

    var email = '';
    var password = '';
    var extras = router.getCurrentNavigation().extras;
    
    if(extras && extras.state)
    {
        if(extras.state?.['email'])
        {
          email = extras.state?.['email'];
        }
      
        if(extras.state?.['password'])
         {
            password = extras.state?.['password'];
         }
     }

      this.form = this.fb.group({
        email: [email, [Validators.required, Validators.email]],
        password: [password, Validators.required]
      });
  

    this.formToken = this.fb.group({
      token: ['', [Validators.required]]
    });

  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  override onSave(): void {
    this.errorMessage = '';
    this.message = '';
    
    this.isLoading = true;
    const user: User = this.form.value;
    
    this.authService.register(user).subscribe(
      (res: ChangeRequestResponse) => {
  

  
        if (res.succeeded) {
          this.logger.info('registration succeeded');          
          this.authService.logout();
          this.showToken = true;
        } else {
          this.errorMessage = res.message;
          this.logger.info(res.message);
        }
      },
      err => {
        this.logger.error(err);
        this.errorMessage = err;
        of([]);
      },
      () => {
        this.logger.info('registration completed');
        this.EndLoading();
      }
    );
  }

  
  onConfirmEmail(): void {
    this.logger.info("start onConfirmEmail") 

    this.errorMessage = '';
    this.message = '';
           
    const email: string = this.form.get('email').value.toString();
    const token: string = this.formToken.get('token').value.toString();
    
    this.logger.info(email); 
    this.logger.info(token); 
    this.logger.info('calling confirmE'); 

    this.authService.confirmEmail(email, token).subscribe(
    (ret: ChangeRequestResponse) => {
 
      if (ret.succeeded) {
       this.logger.info("navigating") 
        this.router.navigate(['/login'], { state: { 
          message: 'Account created.', 
          email: email,
        password: this.form.get('password').value.toString()
       } });
       this.logger.info("done navigating") 
     
        } else {
          this.logger.info("setting error message") 
     
          this.errorMessage = ret.message;
      }
    },
    error => {
      this.errorMessage = error;
      of([]);
    },
    () => {
      this.logger.info('completed sending code');
    }
  );
  }



}
