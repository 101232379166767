import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { throwError, Observable, BehaviorSubject, of } from 'rxjs';
import { LogService } from '../logging/log.service';
import { ErrorDialogModule } from '../logging/error-dialog/error-dialog.module';
import { SettingsService } from '../app-settings/settings.service';
import { IdentityError } from './Models/IdentityError'

@Injectable()
export class BaseServiceService {
  protected loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();
  lastMessage: string = "";


  get projectId(): number {
    return this.settings.projectId;
  }

  constructor(protected logger: LogService, protected settings: SettingsService) {
    // this.logger.info('BaseServiceService constructor');
   // this.logger.info('loadingSubject=' + this.loadingSubject.value);
  }


  public getLastMessage() {
    const message = this.lastMessage;
    this.lastMessage = '';
    return message;
  }

// TODO: change this to a logging intercept?
//   see: https://angular.io/guide/http   'logging-intercept'

  protected startingCall(url: string, data: any) {
    this.loadingSubject.next(true);
    this.logger.info('BaseService:startingCall();');
    this.logger.info(url, data);
  }

  public endCall() {
    this.logger.info('BaseService:endCall();');
    this.loadingSubject.next(false);
  }

  public getErrorLines(obj: any): string {
    let result : string = '';
    for (let id in obj) {
      result = result + obj[id].toString() + ' ';
    }

    return result;
}
 

  // See: https://angular.io/guide/http
  protected handleError(error: HttpErrorResponse | any) {
    this.logger.info("Angular is handling an unknown error after call");
    let userMessage = this.GetErrorMessage(error);
    this.logger.info(userMessage);
    this.loadingSubject.next(false);
 
//throw error;
   // return new Observable<any>();
     //throw new Error(userMessage);
    return throwError(userMessage);
  }

  public GetErrorMessage(error: HttpErrorResponse | any) : string {
    this.logger.info("Angular getting Error Message");
    let userMessage = 'Unknown Error has occurred';
  
    if (typeof error === 'string') {
      userMessage = 'SERVER ERROR: ' + ( error ?? '');
    } else if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      userMessage = `SERVER ERROR ${error.error.message}`;
      this.logger.error(error.error.message);

      //console.error('An error occurred:', error.error.message);
    } else if (error instanceof HttpErrorResponse) {
      const er: HttpErrorResponse = error as HttpErrorResponse;
      if (er.error && er.error.message && er.statusText) {
         userMessage = 'SERVER ERROR: ' + (er.error.message ?? '');
         this.logger.error(userMessage, er.statusText);
        } else if (er.error?.errors) {
          if(er.error.title)
          {
            userMessage = er.error.title + ' ';
          }
          else {
            userMessage = '';
          }    
          
          userMessage = userMessage + this.getErrorLines(er.error.errors);
       
          this.logger.error(userMessage, er.statusText);
        } else if (er.error.detail && er.error.title) {
          userMessage = 'SERVER ERROR: ' + er.error.title + ' ' + er.error.detail;
          this.logger.error(userMessage, er.statusText);
        } else if (er.error.detail) {
          userMessage = 'SERVER ERROR: ' + (er.error.detail ?? '');
          this.logger.error(userMessage, er.statusText);
        } 
        else if (er.error) {
         userMessage = 'SERVER ERROR: ' + (er.error ?? '');
         this.logger.error(userMessage, er.statusText);
        } else if (er.message) {
        userMessage = 'SERVER ERROR: ' + (er.message ?? '');
        this.logger.error(userMessage, er.statusText);
      } else {
        userMessage = 'SERVER ERROR: ' + (er.toString() ?? '');
        this.logger.error(userMessage, er.statusText);
      }
    } else if (error.status) {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      //console.error(
      //  `Backend returned code ${error.status}, ` +
      // `body was: ${error.error}`);
      userMessage = `SERVER ERROR code ${error.status}, body was: ${error.error}`;

      this.logger.error(userMessage);
    } else if (error) {
      if (error.hasOwnProperty('stack')) {
        if (error.hasOwnProperty('message')) {
          userMessage = error.message;
          this.logger.error(error.message, error.stack);
        } else {
          userMessage = 'SERVER ERROR: ' + (error ?? '');
          this.logger.error(userMessage, error.stack);
        }
      } else {
        if (error.hasOwnProperty('message')) {
          userMessage = 'SERVER ERROR: ' + (error.message ?? '');
          this.logger.error(userMessage);

        } else {
          userMessage = 'SERVER ERROR: ' + (error ?? '');
          this.logger.error(userMessage);
    }
      }

      // this.logger.error(error.toString());
    }   else {
      // return an observable with a user-facing error message

      this.logger.error(
        'SERVER ERROR: Communication with server error; please try again later.'
      );

      if (!error.hasOwnProperty('message')) {
        error.message = 'SERVER ERROR: ' + (error ?? '');
      }
  
  }
  this.logger.info(userMessage); 
  return userMessage;
}   

}
