import { Injectable } from '@angular/core';
import { BaseServiceService } from '../shared/base-service.service';
import { Observable } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';
import { LogsWithCount } from './models/Log';
import { SettingsService } from '../app-settings/settings.service';
import { LogService } from './log.service';

@Injectable()
export class LogWebapiService extends BaseServiceService {

  constructor(private http: HttpClient,
    settings: SettingsService,
    logging: LogService) {
    super(logging, settings);
  }


  getLogs(sortColumn: string = 'id',
  sortDirection: string = 'asc',
  pageNumber: number = 0,
  pageSize: number = 25,
  logLevel: number = 0,
  updatedByFilter: string = ''): Observable<LogsWithCount> {
    const url = this.settings.get('BASEURL') + 'infrastructure/Logs';

    this.startingCall(url, null);

    return this.http.get<LogsWithCount>(url, {
        params: new HttpParams()
            .set('sortColumn', sortColumn)
            .set('sortDirection', sortDirection)
            .set('pageNumber', pageNumber.toString())
            .set('pageSize', pageSize.toString())
            .set('logLevel', logLevel.toString())
            .set('updatedByFilter', updatedByFilter)

    }).pipe(
        catchError((err) => this.handleError(err) ),
        finalize(() => this.endCall())
    );
}

getLogExtraInfo(id: string): Observable<string> {
    const url = this.settings.get('BASEURL') + 'infrastructure/LogExtraInfo/' + id;
    this.startingCall(url, id);
    return this.http.get<string>(url).pipe(
        catchError((err) => this.handleError(err) ),
        finalize(() => this.endCall())
    );
}

}
