import { Injectable, OnInit, PLATFORM_ID, Inject } from '@angular/core';
import { Observable, pipe, of, throwError, from } from 'rxjs';
import { map, catchError, finalize } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BaseServiceService } from '../shared/base-service.service';
import { LogService } from '../logging/log.service';
import { ContactUs } from './models/ContactUs';
import { SettingsService } from '../app-settings/settings.service';
import { ChangeRequestResponse } from '../shared/changeRequestResponse';

@Injectable()
export class ContactUsService extends BaseServiceService {

    constructor(private http: HttpClient, settings: SettingsService, logger: LogService) {
        super(logger, settings);
        logger.log('constructor for ContactUsService')
    }

    saveContactUs(contactUs: ContactUs): Observable<ChangeRequestResponse> {
        const url = this.settings.get('BASEURL') + 'infrastructure/SaveContactUs';
        const data = {
            Email: contactUs.email,
            Subject: contactUs.subject,
            Name: contactUs.name,
            Message: contactUs.message,
            UpdatedBy: localStorage.getItem('userId') || 'Anonymous',
            ClientId: 'GITT'
        };

        this.startingCall(url, data);

        return this.http.post<ChangeRequestResponse>(url, data)
            .pipe(
                catchError(err => {     
                    this.logger.error(err);
                    const ret = {} as ChangeRequestResponse;
                    ret.message = err;
                    ret.succeeded = false;
                    return of(ret);
                }),
                finalize(() => this.endCall())
            );

    }

}
