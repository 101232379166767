import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LogService } from '../logging/log.service';
import { SettingsService } from '../app-settings/settings.service';
import { User } from '../login/user';
import { GenericService } from '../services/generic.service';
import { IUrlCrud } from '../interfaces/IUrlCrud';

@Injectable({
  providedIn: 'root'
})
export class UserService extends GenericService<User> {
   
  
    constructor(http: HttpClient, settings: SettingsService, logger: LogService) {
      super(http, settings, logger, 
      { get: 'User/Get/',
        getList: 'User/GetList/',
      delete: 'User/Delete',
      save: 'User/Save' } as IUrlCrud);
  
  
    }
  
  }
  