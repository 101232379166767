import { Component } from '@angular/core';

@Component({
  selector: 'app-git-lab',
  templateUrl: './git-lab.component.html',
  styleUrls: ['./git-lab.component.scss'],
  standalone: false

})
export class GitLabComponent {

}
