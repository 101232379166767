import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, finalize, Observable } from 'rxjs';
import { SettingsService } from '../app-settings/settings.service';
import { LogService } from '../logging/log.service';
import { BaseServiceService } from '../shared/base-service.service';


@Injectable({
  providedIn: 'root'
})
export class CheckoutService extends BaseServiceService {

  constructor(private http: HttpClient, settings: SettingsService, logger: LogService) {
    super(logger, settings);
    logger.info("Starting CheckoutService");
}

  
public savePaymentMethodId(tokenId: string): Observable<string> {

  const url = this.settings.get('BASEURL') + 'Payment/SavePaymentMethodId/' + tokenId;
  this.startingCall(url, null);

  return this.http.get<string>(url).pipe(
    catchError((err) => this.handleError(err)),
    finalize(() => this.endCall())
  );
}



}
