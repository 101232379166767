////// <reference path='../../components/app/app.component.ts' />
import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router'
import { AppSettingsService } from '../app-settings/appsettings.service';
import { HttpClient } from '@angular/common/http';
import { Observable, of, from, Subscription, merge } from 'rxjs';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { LogWebapiService } from './log-webapi.service';
import { Log } from './models/Log';
import { LogListDataSource } from './log-list.datasource';
import { tap } from 'rxjs/operators';
import { LogPublishersService } from './log-publishers.service';
import { LogService } from './log.service';
import { LogLevel } from './models/LogLevel';
import { BaseListComponent } from '../base-list/base-list.component';



@Component({
    selector: 'log-list',
    templateUrl: './log-list.component.html',
    styleUrls: ['./log-list.component.scss']

})
export class LogListComponent extends BaseListComponent
    implements OnInit, AfterViewInit  {

    selectedRow: Log;
    selectedLevel = '0';
    pageSize = 50;

    public logLevels = [
        {level: LogLevel.All.toString(), name: 'All'},
        {level: LogLevel.Debug.toString(), name: 'Debug'},
        {level: LogLevel.Info.toString(), name: 'Info'},
        {level: LogLevel.Warn.toString(), name: 'Warn'},
        {level: LogLevel.Error.toString(), name: 'Error'},
        {level: LogLevel.Fatal.toString(), name: 'Fatal'},
        {level: LogLevel.None.toString(), name: 'None'}

    ];

    
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  
    extraInfo: string;
    public logs: Log[] = [];
    updatedByFilter: string;

    constructor(private router: Router,
        public logWebApiService: LogWebapiService,
        public dataSource: LogListDataSource,
        private logger: LogService) {
                super(logWebApiService);
                this.displayedColumns = ['message', 'logLevel', 'updatedBy', 'updated'];

            this.logger.info('constructor for LogListComponent');

        this.setClickedRow = function (log: Log) {
           this.errorMessage = '';
            this.extraInfo = '';
            this.selectedRow = log;
            logWebApiService.getLogExtraInfo(log.id.toString())
                .subscribe(
                    res => {
                        this.logger.info(`Selected log ` + log.id.toString());
                        this.extraInfo = res
                    },
                    error => {
                        this.extraInfo = 'GETTING LOG ERROR:' + error;
                        logger.error(error);
                    }
                )
        };
    }

    ngOnInit(): void {
        this.dataSource.loadData('id', 'desc', 0,
            this.pageSize);

    }

    ngAfterViewInit(): void {

        this.sort.sortChange.subscribe((e) => {
            this.logger.info('Sorting');
            this.logger.info(this.sort.direction);
            this.paginator.pageIndex = 0;
            this.loadPage();
        });


        merge(this.sort.sortChange, this.paginator.page)
        .pipe(
            tap(() => this.loadPage())
        )
        .subscribe();

    }

    onRefresh(): void {
        this.paginator.pageIndex = 0;
        this.loadPage();
    }

    loadPage(): void {
    this.loadData(this.sort.active, this.sort.direction, this.paginator.pageIndex,
    this.paginator.pageSize);

    }

    loadData(column: string, dir: string, pageIndex: number, pageSize: number) {
        this.logger.info('in loadData()');
        this.logger.info('selectedLevel is ' + this.selectedLevel);

        this.logger.info(`this.sort: ${this.sort}`);

        this.dataSource.loadData(column, dir, pageIndex, pageSize, parseInt(this.selectedLevel, 10),
            this.updatedByFilter);
    }

    testExceptionLog(): void {
        this.logger.error('This is a test error!');
        this.loadPage();
    }

}
