import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { BaseServiceService } from '../shared/base-service.service';
import { LogService } from '../logging/log.service';
import { SettingsService } from '../app-settings/settings.service';
import { ChangeRequestResponse } from '../shared/changeRequestResponse';
import { IUrlCrud } from '../interfaces/IUrlCrud';

export class GenericService<T> extends BaseServiceService {
 
  constructor(public http: HttpClient, settings: SettingsService, logger: LogService, public urls: IUrlCrud) {
    super(logger, settings);
  }

  public get(id: string): Observable<T> {
   
    const url = this.settings.get('BASEURL') + this.urls.get + id;
    this.startingCall(url, id);
    return this.http.get<T>(url).pipe(
      catchError((err) => this.handleError(err)),
      finalize(() => this.endCall())
    );
  }


  public getList(): Observable<T[]> {

    const url = this.settings.get('BASEURL') + this.urls.getList
    this.startingCall(url, null);
    // WinAuth
    //let options = new RequestOptions({ withCredentials: true});
    return this.http.get<T[]>(url).pipe(
      catchError((err) => this.handleError(err)),
      finalize(() => this.endCall())
    );
  }


  public save(obj: T): Observable<ChangeRequestResponse> {
    const url = this.settings.get('BASEURL') + this.urls.save;

    this.startingCall(url, obj);
    return this.http.post<ChangeRequestResponse>(url, obj)
      .pipe(catchError(err => this.handleError(err)),
        finalize(() => this.endCall())
      );

  }

  public delete(id: string): Observable<ChangeRequestResponse> {
    const url = this.settings.get('BASEURL') + this.urls.delete;
    this.startingCall(url, id);
    return this.http.get<ChangeRequestResponse>(url + id)
      .pipe(
        catchError(err => this.handleError(err)),
        finalize(() => this.endCall())
      );

  }

}
