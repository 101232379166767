import { Component } from '@angular/core';

//  
@Component({
  selector: 'app-documentation',
  templateUrl: './documentation.component.html',
  styleUrls: ['./documentation.component.scss']
})
export class DocumentationComponent {

  public selectedVal: string = "G";
  public showMenu = true;

  public onValChange(val: string) {
    this.selectedVal = val;
  }

  public onMenuChange(val: boolean) {
    this.showMenu = val;
  }

}
