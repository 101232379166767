import { Component } from '@angular/core';
import { AppService } from '../../services/app.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LogService } from '../../infrastructure/logging/log.service';
import { TextResponse } from '../../models/TextResponse';

@Component({
  selector: 'app-cancel',
  templateUrl: './cancel.component.html',
  styleUrls: ['./cancel.component.scss']
})
export class CancelComponent {

  constructor(public appService: AppService,
    private logger: LogService,
    activatedRoute: ActivatedRoute,
    public router: Router
  ) {

    logger.info("starting cancel component");
     
    activatedRoute.queryParams.subscribe((param) => {
      const orderId = param['orderId'];
      if(!orderId)
      {
        this.router.navigate(["/product"]);
        return;
      }

      if(localStorage.getItem(orderId) == 'cancel')
      {
        this.router.navigate(["/product"]);
        return;     
      }
    
      localStorage.setItem(orderId, 'canceltry');
      
      this.cancel(orderId, param['params']["sessionId"]);  
    });
    
  }

  cancel(orderId: string, sessionId: string): void {
    
    this.logger.info("orderId: " + orderId + " sessionId: " + sessionId);
    this.appService.cancel(orderId, sessionId).subscribe({
      next: (result: TextResponse) => {
        localStorage.setItem(orderId, 'cancel');
        this.logger.info("in cancel");
        this.logger.info("result: " + result.text);
        //this.router.navigate(["/product"]);
      },
    error: (err) => { this.logger.info('HTTP Error', err)},
    complete: () => this.logger.info('HTTP request completed.')
  });


  }
}
