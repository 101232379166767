import { Component } from '@angular/core';
import { LogService } from '../../infrastructure/logging/log.service';
import { AppService } from '../../services/app.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TextResponse } from '../../models/TextResponse';

@Component({
  selector: 'app-checkout',
  standalone: false,
//  imports: [],
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent {

type: string = "";
quantity: number = 0;

  constructor(public appService: AppService,
    private logger: LogService,
    activatedRoute: ActivatedRoute,
    public router: Router
  ) {

    var status = localStorage.getItem('target');
    
    if(status && status == 'checkout')
    {
      // if they go to stripe and hit back button.
      localStorage.setItem('target', '');
      this.router.navigate(['/licenses']);
      return;
    }



    this.type = activatedRoute.snapshot.params['type'] || localStorage.getItem('type') || "";   
    this.quantity = +(activatedRoute.snapshot.params['quantity'] || localStorage.getItem('quantity') || "");

    logger.info("type is: " + this.type);
    logger.info("quantity is: " + this.quantity);
    
    localStorage.removeItem('type');
    localStorage.removeItem('quantity');


    //TODO: redirect if quantity is zero or type is unknown.

    this.checkout();

  }

  checkout(): void {
    
    switch(this.type)
    {
      case 'trial':
        this.logger.info("Calling trial");
        localStorage.setItem('target', 'checkout/trial/1');
        this.appService.trial().subscribe({
          next: (obj: TextResponse) => {
            this.logger.info("in trial next");
            this.router.navigate(['/licenses']);
          },
        error: (err) => { 
               
          this.logger.error("Trial error " + (err.error ?? err.message ?? err), err); 
          // the authgaurd is never called?
                    
          // the intercepter is never called?
          if(err.status == 401)
          {
            this.router.navigate(['/login'], { queryParams: { returnUrl: this.router.url } });
          }
          else
          {
            localStorage.setItem('message', (err.error ?? err.message ?? err));
            this.router.navigate(['/message']);
          }

          },
        complete: () => this.logger.info('Buy request completed.')
      });
        break;
    
      case "buy":
        this.logger.info("Calling buy");
        localStorage.setItem('target', 'checkout/buy/' + this.quantity);
        this.appService.buy(this.quantity).subscribe({
          next: (obj: TextResponse) => {
            var url = obj.text;
            this.logger.info("in buy next");
            if(url)
            {  
              this.logger.info("window.location.href " + url);
              window.location.href = url;
            } 
            else 
            {
              this.logger.info("session was null");
            }

          },
        error: (err) => { 
          this.logger.info("Buy error "  + (err.error ?? err.message ?? err), err); 

          // the intercepter is never called?
          if(err.status == 401)
            {
              this.router.navigate(['/login'], { queryParams: { returnUrl: this.router.url } });
            }
            else
            {
              localStorage.setItem('message', (err.error ?? err.message ?? err));
              this.router.navigate(['/message']);
            }
          },
        complete: () => this.logger.info('Buy request completed.')
      });
    
        
        break;

      default:
        this.logger.info('unknown checkout type ' + (this.type ?? "(null)"));
        alert('unknown checkout type ' + (this.type ?? "(null)"));
        break;

    }

  }  

}
