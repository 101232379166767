import { Component, OnInit } from '@angular/core';
import {MatCardModule} from '@angular/material/card';
import { Router } from '@angular/router';

@Component({
  selector: 'message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageComponent implements OnInit {

  message: string;

  constructor(private router: Router) { }

  ngOnInit(): void {

    this.message = localStorage.getItem('message');

    if (!this.message) {
      this.router.navigate(['/home']);
    } else {
      localStorage.removeItem('message');
    }
  }
}
