<h2>Script Sorting</h2>
<p>Folders are sorted based on a special file that begins with the prefix <code>gitt_</code>. For instance, a folder containing <code>gitt_0100</code> will appear before one containing <code>gitt_0900</code> in the sort order.</p>
<p>This is an alphanumeric sort. You can only have one file in a directory with a name that starts with <code>gitt_</code>. The file's contents can be empty.</p>

<p>If this special file is not in a directory or that directories parent directory, its path is used as it's sort order.</p>


<p>To override the folder level sort order for a given script, a developer can simply include a comment somewhere in the first 3 lines of the script with override sort value. For example:</p>

<pre><code>/* gitt_9999 */
</code></pre>

<p>After setting thes files up, you can use gitt to see the order using <pre>gitt SortOrder -v ...</pre>

    <p>This allows developers to customize the execution order of scripts without renaming files, giving flexibility in managing complex database changes.</p>

<pre><code>Sort Order
Tables\              from: 0100
Views\               from: 0200
Functions\           from: 0300
Stored Procedures\   from: 0500
Security\Users\      from: 0550
Security\            from: 0600
SystemData\          from: 0700
NoSortFile\          from: NoSortFile\</code></pre>

So in the list above, the <b>Tables</b> directory contained the file gitt_0100 and the <b>Security\Users\</b> directory overrode its parents directory sort order with its own gitt_0550 file.