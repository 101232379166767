import { Component, ViewChild } from '@angular/core';
import { BaseServiceService } from '../shared/base-service.service';
import { MatSort } from '@angular/material/sort';
import { IdName } from '../shared/Models/IdName';
//import { setInjectImplementation } from '@angular/core/src/di/injector_compatibility';

@Component({
  selector: 'app-base-list',
  templateUrl: './base-list.component.html',
  styleUrls: ['./base-list.component.scss']
})
export class BaseListComponent {
  public projectId: number;
  public headMessage: string;
  public message: string;
  public errorMessage: string;
  public setClickedRow: Function;
  public displayedColumns: string[] =  [];
  public isSorting = false;
  public hasInactives: boolean;
  public includeInActive = false;
  public showingEditColumns = false;
  public backlink = '/';
  public initialId = 0;
  public loading = false;
  
  @ViewChild(MatSort, { static: false }) sort: MatSort;


  public statusList: Array<IdName> = [{ id: 0, name: 'All' }, { id: 1, name: 'Active' }, { id: 99, name: 'Non-Active' }];
  public statusId = 1;

  constructor(baseService: BaseServiceService) {
    this.headMessage = baseService.getLastMessage();

    // if coming back from an edit, we want to highlight the row just edited.
    if (history.state && history.state.data && !isNaN(history.state.data)) {
      this.initialId = Number(history.state.data);
    }
  }



  public toggleEditColumns(index: number): void {
    const currentIndex = this.displayedColumns.indexOf('updatedBy');
    if (currentIndex > -1) {
      this.displayedColumns = this.displayedColumns.filter(
        item => item !== 'updatedBy' && item !== 'updated'
      );
      this.showingEditColumns = false;

    } else {
      this.displayedColumns.splice(index, 0, 'updatedBy');
      this.displayedColumns.splice(index, 0, 'updated');
      this.showingEditColumns = true;
    }
  }

  public getInitial(list: any[]): any {
    if (this.initialId > 0) {
      for (const item of list) {
        if (item.id === this.initialId) {
          this.initialId = 0;
          return item;
          break;
        }

      }
    }
    return null;
  }

  public getStatus(): boolean {

    switch (this.statusId) {
      case 0:
        return false;
      case 1:
        return true;
      case 99:
        return false;
      default:
        return false;
    };

  }


  // abstract onCreate(): void;


  // abstract onEdit(id: number): void;


}
