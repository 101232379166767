import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, pipe, of, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { LogPublisher, LogConsole, LogLocalStorage, LogWebApi } from './log-publishers';
import { SettingsService } from '../app-settings/settings.service';
import { LogLevel } from './models/LogLevel';
import { ErrorDialogModule } from './error-dialog/error-dialog.module';
import { MatDialog } from '@angular/material/dialog';


// ****************************************************
// Logging Publishers Service Class
// ****************************************************
@Injectable({
    providedIn: 'root'
})
export class LogPublishersService {

    // Public properties
    publishers: LogPublisher[] = [];

    constructor(private http: HttpClient,
        private settings: SettingsService,
        private dialog: MatDialog) {
        // Build publishers arrays
        this.buildPublishers();
    }

    // *************************
    // Public methods
    // *************************
    // Build publishers array
    public buildPublishers(): void {
        let logPub: LogPublisher;
        console.log('buildPublishers');

        if (!this.settings.isLoaded) {
            console.log('Settings not loaded yet, so only LogConsole');
            logPub = new LogConsole();
            logPub.level = LogLevel['Info'];
            this.publishers.push(logPub);
            return;
        }

        console.log('Settings is loaded, rebuilding loggers.');

        this.publishers = [];
        let logLevel: string = this.settings.get('LogDialogLevel').toLowerCase();

        if (logLevel !== 'off') {
            logPub = new ErrorDialogModule(this.dialog);
            logPub.level = stringToLogLevelValue(logLevel);
            logPub.location = 'screen';
            this.publishers.push(logPub);
        }

        logLevel = this.settings.get('LogConsoleLevel').toLowerCase();

        if (logLevel !== 'off') {
            logPub = new LogConsole();
            logPub.level = stringToLogLevelValue(logLevel);
            this.publishers.push(logPub);
        }

        logLevel = this.settings.get('LogWebApiLevel').toLowerCase();

        if (logLevel !== 'off') {
            logPub = new LogWebApi(this.http);
            logPub.level = stringToLogLevelValue(logLevel);
            logPub.location = this.settings.get('BASEURL') + 'Infrastructure/Log';
            this.publishers.push(logPub);
        }

        /*
        logLevel = this.settings.get('LogLocalStorageLevel').toLowerCase();

        if (logLevel !== 'off') {
            logPub = new LogLocalStorage();
            logPub.level = LogLevel[logLevel.charAt(0).toUpperCase() + logLevel.substr(1)];
            logPub.location = 'logging';
            this.publishers.push(logPub);
        }
*/

    }

    /*
    // *************************
    // Private methods
    // *************************
    private handleErrors(error: any): Observable<any> {
        let errors: string[] = [];
        let msg: string;
        msg = 'Status: ' + error.status;
        msg += ' - Status Text: ' + error.statusText;
        if (error) {
            msg += ' - Exception Message: ' + error.exceptionMessage;
        }
        errors.push(msg);

        console.error('An error occurred', errors);

        return throwError(errors);
    }
    */
}


function stringToLogLevelValue(logLevel: string): number 
{
    const eKey  = logLevel.charAt(0).toUpperCase() + logLevel.substring(1);
    return LogLevel[eKey as keyof typeof LogLevel];
}