import { Component } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { BaseListComponent } from '../../infrastructure/base-list/base-list.component';
import { LogService } from '../../infrastructure/logging/log.service';
import { AppService } from '../../services/app.service';
import { HttpEvent } from '@angular/common/http';
import { BaseServiceService } from '../../infrastructure/shared/base-service.service';

@Component({
  selector: 'app-downloads',
  templateUrl: './downloads.component.html',
  styleUrls: ['./downloads.component.scss']
})
export class DownloadsComponent extends BaseListComponent
  {
    public panelOpen = 0;
    selectedValue =  -1;
    target = "";
    selectionTitle = "";
    filename = "";

    constructor(public appService: AppService,
      private logger: LogService
    ) {
      super(appService);
  
  
      logger.info("Starting Dowwnloads Component");
    }

    // see: https://blexin.com/en/blog-en/uploading-and-downloading-files-with-angular-and-asp-net-core/
    downloadFile(): void { 
   
    }


    changeSelection(value: number): void
    {

      switch(value)
      {
        case 1:
          https://gittdownloads.s3.amazonaws.com/GitToolset-win-x64.msi
          this.target = 'https://gittdownloads.s3.amazonaws.com/GitToolset-win-x64.msi';
          this.selectionTitle = "Windows 64-bit (x86) MSI";
          break;
        case 2:
          this.target = 'https://gittdownloads.s3.amazonaws.com/GitToolset-win-x64.zip';
          this.selectionTitle = "Windows 64-bit (x86) Zip file";
          break;
      case 3:
          this.target = 'https://gittdownloads.s3.amazonaws.com/GitToolset-win-arm64.msi';
          this.selectionTitle = "Windows 64-bit (Arm) MSI";
          break;
      case 4:
          this.target = 'https://gittdownloads.s3.amazonaws.com/GitToolset-win-arm64.zip';
          this.selectionTitle = "Windows 64-bit (Arm) Zip file";
          break;
      case 5:
          this.target = 'https://gittdownloads.s3.amazonaws.com/GitToolset-linux-x64.zip';
          this.selectionTitle = "Linux 64-bit (x86) Zip file";
          break;
      case 6:
          this.target = 'https://gittdownloads.s3.amazonaws.com/GitToolset-linux-arm64.zip';
          this.selectionTitle = "Linux 64-bit (Arm) Zip file";

          break;
  
      }

      this.selectedValue = value;
    }

}
