

<page-header title="Sales"  [loading]='(appService.loading$ | async) || false' [errorMessage]='errorMessage' ></page-header>

<h3>{{headMessage}}</h3>


<table mat-table [dataSource]='dataSource' matSort class='mat-elevation-z8 list'>


  <ng-container  matColumnDef='licenseId'>
    <th mat-header-cell mat-sort-header *matHeaderCellDef> License Id </th>
    <td mat-cell *matCellDef='let element'> {{element.licenseId}} &nbsp;        
    </td>
  </ng-container>

  
  <ng-container  matColumnDef='userName'>
    <th mat-header-cell mat-sort-header *matHeaderCellDef> User Name </th>
    <td mat-cell *matCellDef='let element'> {{element.userName}} &nbsp;        
    </td>
  </ng-container>
  
  <ng-container  matColumnDef='email'>
    <th mat-header-cell mat-sort-header *matHeaderCellDef> Email </th>
    <td mat-cell *matCellDef='let element'> {{element.email}} &nbsp;        
    </td>
  </ng-container>
  
  <ng-container matColumnDef='emailConfirmed'>
    <th mat-header-cell mat-sort-header *matHeaderCellDef> Confirmed </th>
    
    <td mat-cell *matCellDef='let element'> 
        <mat-checkbox (click)="$event.stopPropagation()"
        [checked]=element.emailConfirmed disabled >
        </mat-checkbox>

         </td>
  </ng-container>


  <ng-container matColumnDef='trialEnd'>
    <th mat-header-cell mat-sort-header *matHeaderCellDef> Trial End </th>
    <td mat-cell *matCellDef='let element'> {{element.trialEnd  | date : 'MM/dd/y' }}  </td>
  </ng-container>

  <ng-container matColumnDef='edited'>
    <th mat-header-cell mat-sort-header *matHeaderCellDef> Edited </th>
    <td mat-cell *matCellDef='let element'> {{element.edited  | date : 'MM/dd/y' }}  </td>
  </ng-container>


  <tr mat-header-row *matHeaderRowDef='displayedColumns'></tr>
  <tr mat-row *matRowDef='let row; columns: displayedColumns;'></tr>
</table>
