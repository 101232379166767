import { Component, Input } from '@angular/core';

@Component({
  selector: 'page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss']
})
export class PageHeaderComponent {
  @Input() errorMessage: string = "";
  @Input() loading: boolean = false;
  @Input() title: string = "";
  @Input() message: string = "";
  @Input() headerMessage: string = "";

}
