import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Validators, FormBuilder } from '@angular/forms';
import { AuthService } from './auth.service';
import { User } from './user';
import { finalize, of } from 'rxjs';
import { TokenResponse } from './tokenResponse';
import { ChangeRequestResponse } from '../shared/changeRequestResponse';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../controls/confirmation-dialog.component';
import { BaseComponent } from '../base/base.component';
import { LogService } from '../logging/log.service';
import { SettingsService } from '../app-settings/settings.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent extends BaseComponent {
  hide = true;
  showLinkMessage = false;
  instructions = "Please login or create a new account.";

  constructor(
    activatedRoute: ActivatedRoute,
    router: Router,
    fb: FormBuilder,
    logger: LogService,
    settings: SettingsService,
    public authService: AuthService,
    private dialog: MatDialog
  ) {
    super(activatedRoute, router, fb, settings, logger);

    var email = '';
    var password = '';

    var extras = router.getCurrentNavigation().extras;
    
    if(extras && extras.state)
    {
      if(extras.state?.['message'])
      {
        this.message = extras.state?.['message'];
      }
      
      if(extras.state?.['instructions'])
      {
          this.instructions = extras.state['instructions'];
      }

      if(extras.state?.['email'])
      {
        email = extras.state?.['email'];
      }
     
      if(extras.state?.['password'])
      {
        password = extras.state?.['password'];
      }

    }

    this.form = this.fb.group({
      email: [email, [Validators.required, Validators.email]],
      password: [password, Validators.required]
    });
 
    console.log(this.message); 
    

    // incase an intercepter sent us here.
    this.authService.endCall();
    
  }

  override onSave(): void {
    const user: User = this.form.value;
    this.StartingLoading();


    this.authService.login(user).subscribe(
      (res: TokenResponse) => {
        this.logger.info('in login.subscribe');

        if (!res) {
          this.logger.error('res is null');
          return;
        }

        if (res.token) {
          this.logger.info('login succeeded.');
          this.authService.setLogin(user);
          var target = localStorage.getItem("target");
          if(target)
          {
            localStorage.setItem("target", '');
            this.returnUrl = target;
          } else if (!this.returnUrl) {
              this.returnUrl = '/product';
          }
          this.logger.info('this.returnUrl = ' + this.returnUrl);
          this.instructions = "(Check your spam folder if haven't received the email.)"
          if (this.returnUrl === '/') {
            localStorage.setItem('message', 'You are now logged in.');
            this.router.navigate(['/message']);
          } else {
            this.router.navigate([this.returnUrl]);
          }
        } else {

          this.errorMessage = res.message;

          const dialogConfig = new MatDialogConfig();
          // dialogConfig.disableClose = true;
          // dialogConfig.autoFocus = true;
          dialogConfig.data = {
            prompt: 'Login Failed: ' + res.message,
            buttonText: 'Ok'
          };
          // dialogConfig.hasBackdrop = true;
          const dialogRef = this.dialog.open(
            ConfirmationDialogComponent,
            dialogConfig
          );

          dialogRef.afterClosed().subscribe(resDialog => {});
          this.logger.info('login failed, message: ' + res.message);
    
        }
      },
      err => {       

        if(err.hasOwnProperty("status"))
        {
          this.errorMessage = err.status + ' - ' + this.authService.GetErrorMessage(err);
          if(err.status == 401)
          {
            this.showLinkMessage = true; 
          }
        } 
        else 
        {
          this.errorMessage =this.authService.GetErrorMessage(err);
          this.showLinkMessage = false;   
        }
        this.logger.info(this.errorMessage);
        of([]);
      },
      () => {
        this.logger.info('completed');
            }
          )
          .add(() => {
            this.EndLoading();  
            this.isLoading = false;
          this.authService.endCall();
          });

  }

  register(): void {
   
    this.router.navigate(['/register'], { state: { 
      email: this.form.get('email').value,
      password: this.form.get('password').value
   }} );
    
  }


  forgot(): void {
    if (this.hasError('email') || !this.form.get('email').value) {
      this.errorMessage = 'Please enter your email address first.';
      this.logger.info('Please enter your email address first.');
      return;
    }
    this.StartingLoading();

    this.authService.user.email = this.form.get('email').value;

    this.authService
      .resetPasswordRequest(this.form.get('email').value)
      .subscribe(
        (res: ChangeRequestResponse) => {
          if (res.succeeded) {
            this.router.navigate(['/resetPassword']);
          } else {
            this.logger.info('resetPassword' + res.message);
            this.errorMessage = res.message;
          }
        },
        err => {       this.EndLoading();

          this.logger.error(err);
          this.errorMessage = err;
          of([]);
        },
        () => {
          this.EndLoading();
          this.logger.info('in complete');
        }
      );
  }

  
  resend(): void {
    if (this.hasError('email') || !this.form.get('email').value) {
      this.errorMessage = 'Please enter your email address first.';
      this.logger.info('Please enter your email address first.');
      return;
    }
    this.StartingLoading();

    this.authService.user.email = this.form.get('email').value;

    this.authService
      .resendConfirmation(this.form.get('email').value)
      .subscribe(
        (res: ChangeRequestResponse) => {
          if (res.succeeded) {         
            localStorage.setItem('message', "Confirmation Email Sent");
            this.router.navigate(['/message']);
           } else {
            //this.logger.info('resetPassword' + res.message);
            this.errorMessage = res.message;
          }
        },
        err => {       this.EndLoading();

          this.logger.error(err);
          this.errorMessage = err;
          of([]);
        },
        () => {
          this.EndLoading();
          this.logger.info('in complete');
        }
      );
  }
}
