import { OnInit, AfterViewInit } from '@angular/core';
import {
  FormGroup,
  FormBuilder
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LogService } from '../logging/log.service';
import { BaseEntity } from '../shared/Models/BaseEntity';
import { Observable, of } from 'rxjs';
import { SettingsService } from '../app-settings/settings.service';

export abstract class BaseComponent {
  form: FormGroup;
  public projectId: number;
  public title: string;
  public message: string;
  public headerMessage: string;
  public isLoading: boolean = false;
  public isNavAway = false;
  public isNew: boolean;
  public id: string;
  public errorMessage: string;
  public isDirty = false;
  public isFormValid = false;
  public returnUrl: string;

  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    protected fb: FormBuilder,
    protected settings: SettingsService,
    protected logger: LogService
  ) {

    this.id = this.route.snapshot.paramMap.get('id') || '0';
    this.isNew = this.id === '0';

  }

  getFormControl(name: string): any {
    return this.form.get(name);
  }

  // returns TRUE if the FormControl is valid
  isValid(name: string): boolean {
    const e = this.getFormControl(name);
    return e && e.valid;
  }

  // returns TRUE if the FormControl has been changed
  isChanged(name: string): boolean {
    const e = this.getFormControl(name);
    return e && (e.dirty || e.touched);
  }

  // returns TRUE if the FormControl is invalid after user changes
  hasError(name: string): boolean {
    const e = this.getFormControl(name);
    return e && (e.dirty || e.touched) && !e.valid;
  }

  protected StartingLoading(): void {
    this.message = '';
    this.errorMessage = '';
    this.isLoading = true;
    this.logger.info("BaseComponent:StartingLoading()");
  }

  protected EndLoading(): void {
    this.isLoading = false;
    this.logger.info("BaseComponent:EndLoading()");
  }

  protected SetDefaults(baseEntity: BaseEntity): void {
    baseEntity.id = 0;
    baseEntity.isActive = true;
    baseEntity.updated = new Date();
    baseEntity.updatedBy = '(new)';
  }

  canDeactivate(): Observable<boolean> {
    if (this.isNavAway || this.isLoading || (this.form && this.form.pristine)) {
      return of(true);
    }


    return of(confirm('Ok to lose changes?'));
  }

  onCommand($event: any): void {
    switch ($event) {
      case 'save':
        this.onSave();
        break;
      case 'cancel':
        this.onBack();
    }
  }

  public onBack(): void {
    this.isNavAway = true;
    this.router.navigateByUrl(this.settings.popNavUrl());
  }


  onSave(): void {
    alert('in base save');
  }
}


