<page-header [loading]='(authService.loading$ | async) || false' [errorMessage]='errorMessage' ></page-header>

<div class='signin-content'>
  <mat-card>
    <mat-card-content>
      <form [formGroup]='form'>
        <h3>Change Password for {{email}}</h3>
  
        <input matInput placeholder='Old Password' [type]="hideOld ? 'password' : 'text'"
        formControlName='oldPassword' required />

        <mat-form-field class='full-width-input'>
          <button mat-icon-button
          matSuffix
          (click)="hide = !hide"
          [attr.aria-label]="'Hide password'"
          [attr.aria-pressed]="hide">
               <mat-icon matSuffix  (click)='hideOld = !hideOld'>{{hideOld ? 'visibility' : 'visibility_off'}}</mat-icon>
             </button>
          <mat-error *ngIf="hasError('oldPassword')">
            Please enter your old password
          </mat-error>
        </mat-form-field>
       

        
        <mat-form-field class='full-width-input'>
          <input matInput placeholder='New Password' [type]="hide ? 'password' : 'text'" 
                 formControlName='password' required />
                 <button mat-icon-button
                 matSuffix
                 (click)="hide = !hide"
                 [attr.aria-label]="'Hide password'"
                 [attr.aria-pressed]="hide">
                      <mat-icon matSuffix  (click)='hide = !hide'>{{hide ? 'visibility' : 'visibility_off'}}</mat-icon>
                    </button>
            <mat-error *ngIf="hasError('password')">
            Please enter your new password
          </mat-error>
        </mat-form-field>
        <button mat-raised-button color='primary' type='button' [disabled]='form.invalid' (click)='onSave()'>Change</button>
      </form>
      <br>
      
    </mat-card-content>
  </mat-card>
</div>
