import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { HttpClient, HttpEvent } from '@angular/common/http';
import { BaseServiceService } from '../infrastructure/shared/base-service.service';
import { LogService } from '../infrastructure/logging/log.service';
import { SettingsService } from '../infrastructure/app-settings/settings.service';
//import { ChangeRequestResponse } from '../infrastructure/shared/changeRequestResponse';
import { License } from '../models/License';
import { ProductPage } from '../models/ProductPage';
import { TextResponse } from '../models/TextResponse';
import { Sale } from '../models/Sale';

@Injectable({
  providedIn: 'root'
})
export class AppService  extends BaseServiceService {

  constructor(private http: HttpClient, settings: SettingsService, logger: LogService) {
    super(logger, settings);
}

  getLicenses(): Observable<License[]> {
    const url = this.settings.get('BASEURL') + 'App/Licenses';
    this.startingCall(url, null);
    return this.http.get<License[]>(url).pipe(
        finalize(() => this.endCall())
    );
}

getSales(): Observable<Sale[]> {
  const url = this.settings.get('BASEURL') + 'App/hey';
  this.startingCall(url, null);
  return this.http.get<Sale[]>(url).pipe(
      finalize(() => this.endCall())
  );
}

  buy(quantity: number): Observable<TextResponse> {
    const url = this.settings.get('BASEURL') + 'Payment/Buy?productId=1&quantity=' + quantity.toString()+'&clientURL='+window.location.origin;
    this.startingCall(url, null);
    return this.http.get<TextResponse>(url).pipe(
        finalize(() => this.endCall())
    );
  }

  trial(): Observable<TextResponse> {
    const url = this.settings.get('BASEURL') + 'Payment/Trial?productId=1';
    this.startingCall(url, null);
    return this.http.get<TextResponse>(url).pipe(
        finalize(() => this.endCall())
    );
  }

  getProduct(id: number): Observable<ProductPage> {
    const url = this.settings.get('BASEURL') + 'App/Product?id=' + id;
    this.startingCall(url, null);
    return this.http.get<ProductPage>(url).pipe(
        finalize(() => this.endCall())
    );
}


success(orderId: string, sessionId: string): Observable<TextResponse> {
  const url = this.settings.get('BASEURL') + 'Payment/success?orderId=' + orderId + "&sessionId=" + sessionId;
  this.startingCall(url, null);
  return this.http.get<TextResponse>(url).pipe(
      finalize(() => this.endCall())
  );
  }

  cancel(orderId: string, sessionId: string): Observable<TextResponse> {
    const url = this.settings.get('BASEURL') + 'Payment/cancel?orderId=' + orderId + "&sessionId=" + sessionId;
    this.startingCall(url, null);
    return this.http.get<TextResponse>(url).pipe(
        finalize(() => this.endCall())
    );
    }

    //see: https://blexin.com/en/blog-en/uploading-and-downloading-files-with-angular-and-asp-net-core/
    
    downloadFile(fileName: string): Observable<HttpEvent<Blob>> {
      const url = this.settings.get('BASEURL') + 'App/Download/' + fileName;
      this.startingCall(url, null);
      return this.http.get<Blob>(url, { observe: 'response', responseType: 'blob' as 'json'}).pipe(
          finalize(() => this.endCall())
      );
    }


}


