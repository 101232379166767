import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {
  FormGroup,
  Validators,
  FormBuilder
} from '@angular/forms';
import { of } from 'rxjs';
import { ChangeRequestResponse } from '../shared/changeRequestResponse';
import { LogService } from '../logging/log.service';
import { ContactUsService } from './contact-us.service';
import { ContactUs } from './models/ContactUs';
import { BaseComponent } from '../base/base.component';
import { SettingsService } from '../app-settings/settings.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent extends BaseComponent {
  hide = true;
  
  constructor(
    fb: FormBuilder,
    router: Router,
    logger: LogService,
    settings: SettingsService,
    route: ActivatedRoute,
    public contactUsService: ContactUsService
  ) {
    super(route, router, fb, settings, logger);

   
    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email, Validators.maxLength(128)]],
      subject: ['', [Validators.required, Validators.maxLength(128)]],
      name: ['', [Validators.maxLength(128)]],
      message: ['', [Validators.required, Validators.maxLength(4000)]]
    });
    this.logger.info('Form setup');
  }

  override onSave(): void {
    this.logger.info('starting onSave()');


    if (this.form.valid) {
      this.StartingLoading();
      const contactUs: ContactUs = new ContactUs();
      contactUs.email = this.form.value.email;
      contactUs.name = this.form.value.name;
      contactUs.subject = this.form.value.subject;
      contactUs.message = this.form.value.message;

      this.contactUsService.saveContactUs(contactUs).subscribe(
        (res: ChangeRequestResponse) => {
          this.isLoading = false;

          if (res.succeeded) {
            localStorage.setItem('message', res.message);
            this.isNavAway = true;
            this.router.navigate(['/message']);
          } else {
            this.errorMessage = res.message;
            this.logger.info(res.message);
          }
        },
        err => {       this.EndLoading();

          this.logger.error(err);
          this.errorMessage = err;
          of([]);
        },
        () => {
          this.EndLoading();
          this.logger.info('completed');
          this.isLoading = false;
        }
      );
    }
  }
}
