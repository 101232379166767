import { Injectable, Injector } from '@angular/core';
import { HttpHandler, HttpEvent, HttpInterceptor, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { AuthService } from './auth.service';
import { catchError, Observable, throwError} from 'rxjs';
import { TokenService } from './token.service';
import { Router } from '@angular/router';
import { LogService } from '../logging/log.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(protected tokenService: TokenService, private router: Router, private logger: LogService) { }

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler): Observable<HttpEvent<any>> {
        this.logger.info("starting intercept");
            
        console.log("AuthInterceptor");        
        //console.log(request);        

        if(request.url.includes("AWSAccessKeyId"))
        {
            console.log("AWSAccessKeyId");
            return next.handle(request);
        }

        const token =  this.tokenService.getToken();

        if (token) {
            this.logger.info("adding token to request");
            request = request.clone({ setHeaders: { Authorization: `Bearer ${token}`}});
        }

        this.logger.info("calling next handle");

        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
              // Check if it's a 401 Unauthorized error
              if (error.status === 401) {
                // Handle the 401 response (e.g., logout, redirect to login page, etc.)
                //this.authService.logout(); // Log the user out
                this.router.navigate(['/login']); // Redirect to the login page
              }
              throw error; // Re-throw the error after handling
            })
          );
    }



}
