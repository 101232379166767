import { Component } from '@angular/core';

@Component({
  selector: 'app-sort-orders',
  templateUrl: './sort-orders.component.html',
  styleUrl: './sort-orders.component.scss'
})
export class SortOrdersComponent {

}
