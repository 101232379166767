import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { AppSettings } from './models/appsettings';
import { AuthService } from '../login/auth.service';

@Injectable({
    providedIn: 'root'
})
export class SettingsService {

    private settings: AppSettings = new AppSettings();
    isLoaded = false;

    private _projectIdSubject = new BehaviorSubject<number>(+(localStorage.getItem('projectId') ?? '0'));
    projectId$ = this._projectIdSubject.asObservable();


    set projectId(value: number) {
        if(value !== 0)
        {
            localStorage.setItem('projectId', value.toString());
            console.log(`Called localStorage.setItem('projectId', ${value.toString()})`);
            this._projectIdSubject.next(value);
        } 
        else 
        {
            console.log(`Skipping setting 'projectId' to ${value.toString()})`);
        }
    }

    get projectId(): number {
        return +(localStorage.getItem('projectId') ?? '0');
    }

    public navUrls: Array<string> = [];


    constructor(private http: HttpClient) {

    }

    public pushNavUrl(url: string): void {
        this.navUrls.push(url);
    }

    public popNavUrl(): string {
        return this.navUrls.pop() ?? "";
    }

    loadAppSettingsDictionary(baseUrl: string, authService: AuthService): Promise<AppSettings> {
        console.log(`Starting loadAppSettingsDictionary`);
        this.settings = new AppSettings();
        this.settings['BASEURL'] = baseUrl;
        console.log(`Set BASEURL=` + baseUrl);

        this.settings["ORGID"] = "0";
        this.settings["LOGCONSOLELEVEL"] = "info";
        this.settings["LOGDIALOGLEVEL"] = "Error";
        this.settings["LOGLOCALSTORAGELEVEL"] = "Error";
        this.settings["LOGWEBAPILEVEL"] = "Error";
        this.isLoaded = true;
        
        return new Promise<any>((resolve) => {  
            setTimeout(() => {
                console.log('Wake up the Lambda');
                this.http.get<AppSettings>(baseUrl + 'Infrastructure/AppSettingsDictionary').pipe(
                    tap((response: AppSettings) => {
                        console.log(`Processing response.`);
    
                        Object.keys(response).forEach(key => {
                            console.log(`Key: ` + key.toUpperCase() + ` value: ` + response[key]);
                            this.settings[key.toUpperCase()] = response[key];
                        });
                       
                        this.isLoaded = true;
                        console.log(`Done Getting App Settings Dictionary`);
    
                    }),
                    catchError((error: any) => {
                        if (error.status === 403) {
                            alert('Please request that the administrator added you to the system');
                            return throwError(error);
                        }
    
    
                        if (error.hasOwnProperty('message')) {
                            alert(error.message);
                        } else {
                            alert(error);
                        }
                        console.error(error);
                        // this.handleErrors(error.error);
                        return throwError(error);
                    })
                ).subscribe();
            }, 2000);
            
            console.log('Done loadAppSettingsDictionary');
            
            resolve(7); });

        // takes too long to load from Lambda.
       /*
        return this.http.get<AppSettings>(baseUrl + 'Infrastructure/AppSettingsDictionary')
            .pipe(
                tap((response: AppSettings) => {
                    console.log(`Processing response.`);

                    Object.keys(response).forEach(key => {
                        console.log(`Key: ` + key.toUpperCase() + ` value: ` + response[key]);
                        this.settings[key.toUpperCase()] = response[key];
                    });
                    
                    this.settings["ORGID"] = localStorage.getItem('orgId') || "0";
                   
                    this.isLoaded = true;
                    console.log(`Done Getting App Settings Dictionary`);

                }),
                catchError((error: any) => {
                    if (error.status === 403) {
                        alert('Please request that the administrator added you to the system');
                        return throwError(error);
                    }


                    if (error.hasOwnProperty('message')) {
                        alert(error.message);
                    } else {
                        alert(error);
                    }
                    console.error(error);
                    // this.handleErrors(error.error);
                    return throwError(error);
                })
            ).toPromise();
        // ).toPromise();
*/
    }


    getFilesURL(): string {
        return this.get('BASEURL') + `file/document/${this.get('OrgId')}/${this.projectId}/`;
    }

    get(key: string): string {
        if (!this.isLoaded) {
            return localStorage.getItem(key) ?? localStorage.getItem(key.toUpperCase()) ?? '';
        }

        const ret = this.settings[key.toUpperCase()];
        if (!ret) {
            console.error('AppSetting for key=' + key + ' was null.');
            console.error(JSON.stringify(this.settings));
            throw new Error('No app setting found for key=' + key);
        }

        return ret.toString();
    }

    hasKey(key: string): boolean {

        const ret = this.settings[key.toUpperCase()];

        if (!ret) {
            return false;
        }

        return true;
    }

    add(key: string, value: string) {
        if(!this.settings)
        {
            return;
        }
        this.settings[key.toUpperCase()] = value;
    }

    public logout(): void {
        //this.projectId = 0;
    }

}
