<page-header title="Downloads"  [loading]='(appService.loading$ | async) || false' [errorMessage]='errorMessage' ></page-header>
<h3>{{headMessage}}</h3>
<button mat-raised-button color="primary" routerLink="/licenses" routerLinkActive="active">Licenses</button>
<br>
<br>

<h1>Install or Update to the Latest Version of gitt</h1>
<p>This topic describes how to install or update to the latest release of the Git Toolset Command Line Interface (gitt) on supported operating systems.</p>
<p>For installation select your operating system and architecture.</p>
<br>
<br>
   <mat-radio-group aria-label="Select an option">
    <div  class="flex-container">
        <div class="flex-child">
            <b style="font-size: x-large;">Windows</b>  
        <br>
        <mat-radio-button value="1" class="opOptions" (change)="changeSelection(1)">64-bit (x86) MSI</mat-radio-button>
        <br>
        <mat-radio-button value="2" class="opOptions" (change)="changeSelection(2)">64-bit (x86) ZIP file</mat-radio-button>
        <br>
        <mat-radio-button value="3" class="opOptions" (change)="changeSelection(3)">64-bit (Arm) MSI</mat-radio-button>
        <br>
        <mat-radio-button value="4" class="opOptions" (change)="changeSelection(4)">64-bit (Arm) ZIP file</mat-radio-button>
        </div>
        <br>
        <br>
        <br>
        <div class="flex-child">
            <b style="font-size: x-large;">Linux</b>
        <br>
        <mat-radio-button value="5" class="opOptions" (change)="changeSelection(5)">64-bit (x86)</mat-radio-button>
        <br>
        <mat-radio-button value="6" class="opOptions" (change)="changeSelection(6)">64-bit (Arm)</mat-radio-button>
        <br>
        </div>   
      </div>
</mat-radio-group>
<br>
<br>
<span style="font-size: large; font-weight: bold">{{selectionTitle}}</span>
<br>
<br>
<h4 id="install-windows-instructions">
   Install or update Git Toolset CLI (gitt)
</h4>
<div class="procedure" *ngIf="selectedValue == 1 || selectedValue == 3">
   <ol>
      <li>
         <div>
            <p>
               Download and run the GITT MSI installer for Windows
               (64-bit):
            </p>
            <p>
               <a href="{{target}}" rel="noopener noreferrer" target="_blank">
               <span>{{target}}</span>
               </a>
            </p>
            <p>
               Alternatively, you can run the <code class="code">msiexec</code> command to run
               the MSI installer.
            </p>
            <pre class="programlisting">   <code>C:\&gt;msiexec.exe /i <span>{{target}}</span>
              </code></pre>
            For various parameters that can be used with <code>msiexec</code>, see 
            <a href="https://docs.microsoft.com/en-us/windows-server/administration/windows-commands/msiexec" 
               rel="noopener noreferrer" target="_blank">
            <span>msiexec</span></a>
            on the 
            <em>
            Microsoft
            Docs
            </em> website. For example, you can use the
            <code>/qn</code> flag for a silent installation.
            <pre class="programlisting">   <code>C:\&gt;msiexec.exe /i {{target}}</code></pre>
         </div>
      </li>
      <li>
         <p>
            To confirm the installation, open the <b>Start</b>
            menu, search for <code class="code">cmd</code> to open a command prompt window,
            and at the command prompt use the <code class="code">gitt --version</code>
            command.
         </p>
         <pre class="programlisting">   <code>C:\&gt;gitt --version</code></pre>
         <p>
            If Windows is unable to find the program, you might need to close
            and reopen the command prompt window to refresh the path. Or add the 
            gitt install directory to your path environment variable.
         </p>
      </li>
   </ol>
</div>
<div class="procedure" *ngIf="selectedValue == 2 || selectedValue == 4">
   <ol>
      <li>
         <div>
            <p>
               Download and Unzip the GITT Zip file for Windows
               (64-bit):
            </p>
            <p>
               <a href="{{target}}" rel="noopener noreferrer" target="_blank">
               <span>{{target}}</span>
               </a>
            </p>
            <p>
               Unzip the file into a new directory to hold the software, usually under "C:\Program Files\DigitalTools\GitToolset\" or C:\Users\[USERNAME]\AppData\Local\Programs\DigitalTools\GitToolset\.
            </p>            
            <p>
               Unzip the file into a new directory. <code class="code">{{target}}</code> 
            </p>
         </div>
      </li>
      <li>
         <p>Add the path where you unzipped the program to the PATH environment variable. </p>
      </li>
      <li>
         <p>
            To confirm the installation, open the <b>Start</b>
            menu, search for <code class="code">cmd</code> to open a command prompt window,
            and at the command prompt use the <code class="code">gitt --version</code>
            command.
         </p>
         <pre class="programlisting">   <code>C:\&gt;gitt --version</code></pre>
         <p>
            If Windows is unable to find the program, you might need to close
            and reopen the command prompt window to refresh the path. Or add the 
            gitt install directory to your path environment variable.
         </p>
      </li>
      <li>
         <p>
            Add or update license key with the following command: <br><br>

               gitt AddLicense .e29d6963f43a4416bb74860ac006bc84.725760.1.1.a555f.
             
         </p>
    
      </li>
   </ol>
</div>
<div class="procedure" *ngIf="selectedValue > 4">
   <div class="itemizedlist">
      <ol>
         <li>
            <p>
               Download the installation file in one of the following ways:
            </p>
            <ul class="itemizedlist">
               <li class="listitem">
                  <p>
                     <span class="topcom">
                     Use the
                     <code class="code">curl</code> command
                     </span> – The
                     <code class="code">-o</code> option specifies the file name
                     that the downloaded package is written to. The
                     options on the following example command write the
                     downloaded file to the current directory with the
                     local name <code>gitt.zip</code>.
                  </p>
                  <pre class="programlisting"><code class="prompt" copy="false">$ </code><code class="userinput">curl "{{target}}" -o "gitt.zip"</code></pre>
               </li>
               <li class="listitem">
                  <p>
                     <span class="topcom">
                     Downloading from the URL
                     </span> – To download the installer
                     with your browser, use the following URL: <a href="{{target}}" rel="noopener noreferrer" target="_blank"><span>{{target}}</span>
                     </a>
                  </p>
               </li>
            </ul>
         </li>
         <li>
            <p>
               Unzip the installer. If your Linux distribution doesn't have a
               built-in <code class="code">unzip</code> command, use an equivalent to unzip it.
               The following example command unzips the package and creates a
               directory named <code>gt</code> under the current
               directory.
            </p>
            <pre class="programlisting"><code class="nohighlight"><code class="prompt" copy="false">$ </code><code class="userinput">unzip gitt.zip</code></code></pre>
            <div class="note">
               <div class="note-title">
                  <h6>Note</h6>
               </div>
               <div class="note-text">
                  <p>
                     When updating from a previous version, the <code class="code">unzip</code>
                     command prompts to overwrite existing files. To skip these
                     prompts, such as with script automation, use the <code class="code">-u</code>
                     update flag for <code class="code">unzip</code>. This flag automatically
                     updates existing files and creates new ones as needed.
                  </p>
                  <pre class="programlisting"><code class="nohighlight"><code class="prompt" copy="false">$ </code><code class="userinput">unzip -u gitt.zip</code></code></pre>
               </div>
            </div>
         </li>
         <li>
            <p>
               Run the install program. The installation command uses a file
               named <code>install</code> in the newly unzipped
               <code>gt</code> directory. By default, the files are
               all installed to <code>/usr/local/gt-cli</code>, and a
               symbolic link is created in <code>/usr/local/bin</code>. The
               command includes <code class="code">sudo</code> to grant write permissions to
               those directories.
            </p>
            <pre class="programlisting"><code class="nohighlight"><code class="prompt" copy="false">$ </code><code class="userinput">sudo ./gt/install</code></code></pre>
            <p>
               You can install without <code class="code">sudo</code> if you specify
               directories that you already have write permissions to. Use the
               following instructions for the <code class="code">install</code> command to
               specify the installation location:
            </p>
            <div class="itemizedlist">
               <ul class="itemizedlist">
                  <li class="listitem">
                     <p>
                        Ensure that the paths you provide to the <code class="code">-i</code>
                        and <code class="code">-b</code> parameters contain no volume name or
                        directory names that contain any space characters or other
                        white space characters. If there is a space, the
                        installation fails.
                     </p>
                  </li>
                  <li class="listitem">
                     <p>
                        <code class="code">--install-dir</code> or <code class="code">-i</code> –
                        This option specifies the directory to copy all of the files
                        to.
                     </p>
                     <p>
                        The default value is
                        <code>/usr/local/gt-cli</code>.
                     </p>
                  </li>
                  <li class="listitem">
                     <p>
                        <code class="code">--bin-dir</code> or <code class="code">-b</code> – This
                        option specifies that the main <code class="code">gitt</code> program in
                        the install directory is symbolically linked to the file
                        <code>gitt</code> in the specified path. You must
                        have write permissions to the specified directory. Creating
                        a symlink to a directory that is already in your path
                        eliminates the need to add the install directory to the
                        user's <code class="code">$PATH</code> variable.
                     </p>
                     <p>
                        The default value is <code>/usr/local/bin</code>.
                     </p>
                     <pre><code class="nohighlight"><code class="prompt" copy="false">$ </code><code class="userinput">./gitt/install -i <code class="replaceable">/usr/local/gitt-cli</code> -b <code class="replaceable">/usr/local/bin</code></code></code></pre>
                  </li>
               </ul>
            </div>
         </li>
         <li>
            <p>Confirm the installation with the following command. </p>
            <pre class="programlisting"><code class="nohighlight"><code class="prompt" copy="false">$ </code><code class="userinput">gitt --version</code></code></pre>
            <p>
               If the <code class="code">gitt</code> command cannot be found, you might need to
               restart your terminal or follow the troubleshooting in <a href="./cli-chap-troubleshooting.html">Troubleshoot GITT errors</a>.
            </p>
         </li>
         <li>
            <p>Add or update license key with the following command. </p>
            <pre class="programlisting"><code class="nohighlight"><code class="prompt" copy="false">$ </code><code class="userinput">sudo gitt AddLicense .e29d6963f43a4416bb74860ac006bc84.725760.1.1.a555f.</code></code></pre>
         </li>
      </ol>
   </div>
</div>
<br>
