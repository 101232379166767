import { AfterViewInit, Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { LogService } from '../../infrastructure/logging/log.service';
import { AuthService } from '../../infrastructure/login/auth.service';
import { AppService } from '../../services/app.service';
import { License } from '../../models/License';
import { BaseListComponent } from '../../infrastructure/base-list/base-list.component';
import { DataSource } from '@angular/cdk/collections';
import { Sale } from '../../models/Sale';


@Component({
  selector: 'app-hey',
  templateUrl: './hey.component.html',
  styleUrl: './hey.component.scss'
})
export class HeyComponent extends BaseListComponent
{
  public dataSource: MatTableDataSource<Sale>;

  constructor(public appService: AppService,
    private logger: LogService
  ) {
    super(appService);

    this.displayedColumns = ['licenseId', 'userName', 'email', 'emailConfirmed', 'trialEnd', 'edited'];

    logger.info("Calling Get Sales");
    appService.getSales().subscribe({
      next: (sales) => {
        logger.info("in getSales next");
    
        this.dataSource = new MatTableDataSource<Sale>(sales);
        this.dataSource.sort = this.sort;
      },
    error: (err) => { logger.info('HTTP Error', err)},
    complete: () => logger.info('HTTP request completed.')
  });

  }

}

