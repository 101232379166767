import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-confirmation-dialog',
    templateUrl: './confirmation-dialog.component.html'
})
export class ConfirmationDialogComponent  {

    defaltButtonText: string = 'Yes';
    constructor(
        public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
            if(data.hasOwnProperty('buttonText'))
            {
                this.defaltButtonText = data.buttonText;
            }

         }

    onCancel(): void {
        this.dialogRef.close();
    }

}