<br>
<br>

<div *ngIf="product" class="float-container" style="width: 90%;">
    <span class="product-name" >{{product.name}} -- {{product.title}}</span>


    <div class="float-child first">
        <div class="product-image-container">
        <img src={{product.imageURL}} alt="product.imageTitle" />
    </div>
<br>
<br>
        <b>Platforms:</b>
        
        <ul>
            <li *ngFor="let item of product.platforms">
            &nbsp;&nbsp;&nbsp;<span>{{item}}</span>
         </li>
        </ul>
        
        <br>
        <b>{{product.descriptionTitle}}</b>
        <br>
        <ul>
            <li *ngFor="let item of product.benefits">
                <span>{{item}}</span>
                <br>
                <br>
             </li>
        </ul>
        

    </div>

    <div class="float-child second center">
        <div class="control controlInfo" style="text-align: center; font-size: large;" *ngIf="product"><b>{{product.price}}</b> USD Per License</div>
        <br>
        <div class="control controlInfo" style="border-color: blueviolet; ">Quantity: <input class="controlQty" matInput type="number" placeholder="Quantity" [(ngModel)]="quantity"></div>
        <br>
        <button class="control" mat-raised-button color='primary' type='submit' (click)='buy()'>Buy</button>
        <br>

        <br>
        <br>
                <button class="control" mat-raised-button color='primary' type='button' (click)='trial()'>{{product.trialDays}} Days Free Trial</button><span *ngIf="product"></span>
        

    </div>
    
  </div>

<br>

<br>
<br>
<br>
