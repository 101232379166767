import { ErrorHandler, Injectable, Injector, NgZone} from '@angular/core';
import { LogService } from './log.service';
import { Router } from '@angular/router';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private injector: Injector, private ngZone: NgZone) { 

  }

  handleError(error: any) {
    const logger: LogService = this.injector.get(LogService);
    const message = error.message ? error.message : error.toString();
    localStorage.setItem('message', 'Application Error: ' + message);
    //localStorage.setItem('message2', error.toString());
    const router = this.injector.get(Router);
    console.error('in GlobalErrorHandler');
    console.error(error);

    if (error.rejection.status === 401 || error.rejection.status === 403) {
      router.navigate(['/login']);
    }

    this.ngZone.run(() => {
      logger.error(message, error.stack);
     // router.navigate(['/message']);
    });

  
    router.navigate(['/message']);

    // IMPORTANT: Rethrow the error otherwise it gets swallowed
  //   throw error;
  }

}