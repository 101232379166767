<page-header  
[title]="'Contact Us'" 
[message]='message' 
[headerMessage]=''  
[loading]='false' 
[errorMessage]='errorMessage' >
</page-header>


<div class='signin-content'>
  <mat-card>
    <mat-card-content>
      <form [formGroup]='form'>
        <h2>{{message}}</h2>
        <mat-form-field class='full-width-input'>
          <input matInput placeholder='Name (optional)' formControlName='name' />
          <mat-error *ngIf="hasError('name')">
            Please enter your name
          </mat-error>
        </mat-form-field>

        <br>
      
        <mat-form-field class='full-width-input'>
          <input matInput placeholder='Your Email Address' formControlName='email' required />
          <mat-icon matSuffix>email</mat-icon>
          <mat-error *ngIf="hasError('email')">
            Please enter your email address
          </mat-error>
        </mat-form-field>
        <br>
      
        <mat-form-field class='full-width-input'>

          <input matInput placeholder='Subject' formControlName='subject' required />

          <mat-error *ngIf="hasError('subject')">
            Please enter subject
          </mat-error>
        </mat-form-field>

        <br>
      
        <mat-form-field class='full-width-input'>
          <textarea matInput placeholder='Message' formControlName='message' required></textarea>
          <br>
      
        <mat-error *ngIf="hasError('message')">
          Please enter your message
        </mat-error>
      </mat-form-field>
      <br>
      
        <button mat-raised-button color='primary' [disabled]='!form.valid' (click)='onSave()' type='button'>Send Message</button>

      </form>
      <br>
      <br>
      <br>
      <a routerLink='/' routerLinkActive='home' class='alc'><strong>Cancel</strong></a>
      <br>

    </mat-card-content>
  </mat-card>
</div>

