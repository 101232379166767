import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {
  Validators,
  FormBuilder
} from '@angular/forms';
import { AuthService } from '../login/auth.service';
import { of } from 'rxjs';
import { ChangeRequestResponse } from '../shared/changeRequestResponse';
import { LogService } from '../logging/log.service';
import { BaseComponent } from '../base/base.component';
import { SettingsService } from '../app-settings/settings.service';

@Component({
  selector: 'reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent extends BaseComponent {
  hide = true;
  email: string;

  constructor(
    activatedRoute: ActivatedRoute,
    router: Router,
    fb: FormBuilder,
    settings: SettingsService,
    logger: LogService,
    public authService: AuthService
  ) {
    super(activatedRoute, router, fb, settings, logger);

    this.form = this.fb.group({
      token: ['', [Validators.required]],
      password: ['', Validators.required]
    });

    this.email = authService.user.email;
    if(!this.email)
    {
      this.router.navigate(['/login']);      
    }
  }

  override onSave(): void {
    this.errorMessage = '';

    this.authService.resetPassword(
      this.email,
      this.form.get('token').value,
      this.form.get('password').value
    ).subscribe(
      (res: ChangeRequestResponse) => {
        if (res.succeeded) {
          this.router.navigate(['/login']);
        } else {
          this.logger.info(res.message);
          this.errorMessage = res.message;
        }
      },
      err => {       this.EndLoading();

        this.logger.error(err);
        this.errorMessage = err;
        of([]);
      },
      () => {
        this.EndLoading();
        this.logger.info('completed');
      }
    );
  }
}
