import { Component, Inject } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
    selector: 'app-dialog',
    templateUrl: './dialog.component.html',
    styleUrls: ['./dialog.component.scss']
  })
  export class DialogComponent {
    
    constructor(
      public dialogRef: MatDialogRef<DialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any) {

        console.log('DialogComponent constructor message: ' + this.data.message);
       }
  
    onCancel(): void {
      console.log('in onCancel()');
      
      this.dialogRef.close();
    }
    
  }