    <h1>Automating SQL Change Releases Using Git Toolset</h1>

    <br>
    <h2>Authoring Change Scripts</h2>
    <p>The strategy is to ensure that change scripts are idempotent, meaning they can be run multiple times on the same database without errors. For example:</p>
    <pre><code>IF NOT EXISTS (SELECT *
           FROM INFORMATION_SCHEMA.COLUMNS
           WHERE TABLE_NAME = 'Customers'
           AND COLUMN_NAME = 'Email')
BEGIN
    ALTER TABLE Customers 
    ADD Email NVARCHAR(256);
END
</code></pre>
<br>
Same idea when you add system data:
<pre><code>IF (NOT EXISTS (SELECT * 
    FROM dbo.States 
    WHERE Abbreviation = 'AL'))
BEGIN

    INSERT INTO States
    (Abbreviation, Name)
    VALUES ('AL', 'Alabama'),
           ('AK', 'Alaska'),
           ...
END
</code></pre>
<br>

    <h2>Script Naming</h2>
    <p>Change scripts are SQL updates saved in a file, with the file name corresponding to the database object it creates or modifies. For instance, the script above would be saved as <code>Customers.sql</code>. Files are organized into folders based on the database object type, so the file would be stored as <code>Tables\Customers.sql</code>.</p>

    <h2>Script History</h2>
    <p>Each time you overwrite a script (like <code>Customers.sql</code>), Git tracks the file's change history. This history provides an audit trail for all changes made to that database object.</p>

    <img src='History.png' alt='history'  class='center' />
<br>


<br>
    <h2>Building a Release Script</h2>
    <p>To generate a release script containing all changes since the last release, use the following command:</p>
    <pre><code>gitt Build --start-after-tag-message-containing "release v"
</code></pre>
    <p>(Note: <code>gitt</code> refers to Git Toolset, not Git.)</p>
    <p>This command finds the last tag with a message containing the text “release v,” collects all <code>.sql</code> files from subsequent commits, sorts them based on your rules, accounts for sort order overrides at the script level, and combines them into a single release script, such as <code>Output\MyProjectReleaseScript_20140930152219.sql</code>. This process can easily be integrated into an automated build pipeline.</p>
    <p>This is just one example of the many WHERE clauses used to select the commits for inclusion in the build.</p>
    <p>Developers can use one to generate a script containing all of the files that have been changed since the start of the current branch.</p>
    
    
    <br>
    <h2>Injecting Metadata</h2>
    <p>Git Toolset can inject metadata into release scripts during the build process. For example, you can add templates at the top/bottom of the release script or before/after individual change scripts. These templates use placeholders, which are populated during the build.</p>
    <p>This metadata improves readability and helps pinpoint errors when applying the release script to a database.</p>

<br>
Below is a sample script header template.
<pre><code>
/* 
	 Output File: %o
	Project Name: %p
	      Author: %a
	      Branch: %b
	        Time: %t


     Search Criteria: 
                      %s
	
	 Files: Count       %l

*/
	
Print 'Start Running Script: %p'
Print ''
GO

</code></pre>
<br>
<p>This would be populated and added to the top of the output file at build time.

<pre><code>
/*
         Output File: \work\publish\MyProjectReleaseScript_20240907T1831115807418.sql
        Project Name: MyProject
              Author: Development Group A
              Branch: main
                Time: 09/07/2024 22:31:12


     Search Criteria:
                      Start Criteria: StartAfterTagMessageContaining Release v
                        End Criteria: Head
                              Branch: master
                            Criteria: Commits between 09/07/2024 16:55:53 -04:00 and 09/07/2024 17:35:00 -04:00


         Files: Count 3
                      File Name                                            Committer             Commit Time                  SHA
                      MyProjectDB/Stored Procedures/CustOrdersDetail.sql   JJ-at-13244119888   09/07/2024 17:35:00 -04:00   6d0d68f
                      MyProjectDB/Stored Procedures/CustomerReport.sql     JJ-at-13244119888   09/07/2024 17:35:00 -04:00   6d0d68f
                      MyProjectDB/Tables/Customers.sql                     JJ-at-13244119888   09/07/2024 16:55:53 -04:00   1de166a

*/

Start Running Script \work\MyProjectReleaseScript_20240907T1845396398702.sql

</code></pre>
<br>
<p>For each included file</p>

<pre><code>Print '   Start Processing: %p'
Print '   Last Commit Time: %ct'
Print ' Last Commit Author: %ca'
Print '    Last Commit SHA: %cs'
Print 'Last Commit Message: %cm'
</code></pre>




<p>Becomes</p>

<pre><code>Print '   Start Processing: MyProjectDB/Stored Procedures/CustOrdersDetail.sql'
Print '   Last Commit Time: 09/07/2024 17:35:00 -04:00'
Print ' Last Commit Author: JJ-at-13244119888'
Print '    Last Commit SHA: 6d0d68f3a25d54e32c575073425ae6282c15fb99'
Print 'Last Commit Message: Updated customers related stored procs'
</code></pre>
<p>The file-level metadata provides the added benefit of identifying the source of errors and supplying all necessary information when the release script is applied to a database.</p>
<br>
<img src='Output.png' alt='output' class='center' />


<br>

    <h2>Git Repository Structure</h2>
    <p>While database scripts can be stored in a separate repository, we recommend keeping them in the same repository as your front-end code. For example:</p>
<ul>
<li>📁 MyProject
	<ul>
    	<li>📁 Code</li>
    	<li>📂 Database
        	<ul>
            	<li>📁 Functions</li>
            	<li>📁 Security</li>
            	<li>📁 Stored Procedures</li>
            	<li>📁 Tables</li>
            	<li>📁 Views</li>
        	</ul>
    	</li>
	</ul>
</li>
</ul>


    <p>This setup ensures that changes to both the database and front-end (e.g., adding an <code>Email</code> column in the <code>Customers</code> table and updating the customer edit page) are included in the same commit. This improves traceability and simplifies operations like cherry-picking, reverting commits or seeing a complete picture when you view commit details.</p>
    <br>
    <h2>Next Steps</h2>
    <ul>
       <!-- <li><a href="#">Watch the video</a></li>-->
       <li>Review <a href="/documentation">Documentation</a></li>
        <li>Obtain a license from <a href="/product">Product</a></li>
        <li>Download and install Git Toolset from <a href="/downloads">Download Git Toolset</a></li>
    </ul>
<br><br><br>

