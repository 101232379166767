<h1 mat-dialog-title>{{data.title}}</h1>

<div mat-dialog-content [innerHTML]='data.prompt'></div>
<div mat-dialog-actions *ngIf="defaltButtonText=='Yes'">
    <button type='button' mat-button (click)='onCancel()'  cdkFocusInitial>No</button>
    <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
    <button mat-button [mat-dialog-close]='true'>Yes</button>
</div>

<div mat-dialog-actions *ngIf="defaltButtonText!=='Yes'">
    <button mat-button [mat-dialog-close]='true'>{{defaltButtonText}}</button>
</div>
