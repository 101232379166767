<h2>{{ title }}</h2>


<div class='spinner-container' *ngIf='loading'>
  <mat-spinner></mat-spinner>
</div>
<div *ngIf='errorMessage' class='errorMessage'>
   {{errorMessage}}
   <br>
  </div>


<strong *ngIf='message'>{{message}}
  <br>
  <br>  
</strong>

<mat-card *ngIf='headerMessage'>
    {{headerMessage}}
</mat-card>

<br>