<h1>Logs</h1>

<h3>{{headMessage}}</h3>


<div class='spinner-container' *ngIf='dataSource.loading$ | async' (keyup.enter)='loadData()'>
  <mat-spinner></mat-spinner>
</div>
<br>
<br>
<mat-form-field>
  <mat-select placeholder='LogLevel Filter' [(value)]='selectedLevel'>
    <mat-option *ngFor='let level of logLevels' value='{{level.level}}'>
      {{ level.name }}
    </mat-option>
  </mat-select>
</mat-form-field>
&nbsp;
&nbsp;
<mat-form-field>
  <input matInput placeholder='EdityBy Filter' [(ngModel)]='updatedByFilter' />
</mat-form-field>
&nbsp; &nbsp;

<button mat-button color='primary' (click)='onRefresh()'>Refresh</button>
<br>

<br />
<b *ngIf='extraInfo'>Selected Log's Extra Info:</b>
<br />
<p>{{extraInfo}}</p>
<br />
<br />

<table mat-table [dataSource]='dataSource' class='mat-elevation-z8 list' matSort matSortActive='id' matSortDirection='desc'
  matSortDisableClear>

  <ng-container  matColumnDef='message'>
    <th mat-header-cell mat-sort-header *matHeaderCellDef> Message </th>
    <td mat-cell *matCellDef='let element'> {{element.message}} </td>
  </ng-container>

  <ng-container matColumnDef='logLevel'>
    <th mat-header-cell mat-sort-header *matHeaderCellDef> Log Level </th>
    <td mat-cell *matCellDef='let element'> {{element.logLevel}} </td>
  </ng-container>

  <ng-container matColumnDef='updatedBy'>
    <th mat-header-cell mat-sort-header *matHeaderCellDef> Updated By </th>
    <td mat-cell *matCellDef='let element'> {{element.updatedBy}} </td>
  </ng-container>

  <ng-container matColumnDef='updated'>
    <th mat-header-cell mat-sort-header *matHeaderCellDef> Updated </th>
    <td mat-cell *matCellDef='let element'> {{element.updated | date : 'MM/dd/y HH:mm' }} </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef='displayedColumns'></tr>
  <tr mat-row *matRowDef='let row; columns: displayedColumns;' (click)='setClickedRow(row)' [ngClass]="{'active': row == selectedRow}"></tr>
</table>

<mat-paginator [pageSize]='50' [length]='dataSource.count' [pageSizeOptions]='[25, 50, 100, 500, 1000]'></mat-paginator>

<button mat-button color='primary' (click)='testExceptionLog()'>Test Exception Logging</button>
