import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, finalize, map, Observable } from 'rxjs';
import { SettingsService } from '../app-settings/settings.service';
import { LogService } from '../logging/log.service';
import { BaseServiceService } from '../shared/base-service.service';
import { TokenResponse } from './tokenResponse';



@Injectable({
    providedIn: 'root'
  })
  
export class TokenService extends BaseServiceService {
    authKey = 'auth';
    baseURL: string = "";

    public tokenData: any = {};  
    private _tokenDataSubject = new BehaviorSubject<any>(null);
    public tokenDataSubject$ = this._tokenDataSubject.asObservable();  

    
  constructor(
    settings: SettingsService,
    private http: HttpClient,
    logger: LogService
  ) {
    super(logger, settings);

    this.logger.info('Constructor for AuthService.');

    const authData = this.getTokenResponse();
    if (authData != null) {
      this.setTokenData(authData.token);  
    }
  }

  
    setTokenResponse(tokenResponse: TokenResponse): void {
        localStorage.setItem(this.authKey, JSON.stringify(tokenResponse));  
    }

    clearTokenResponse(): void {
        localStorage.removeItem(this.authKey);  
    }
  
  getTokenResponse(): TokenResponse | null {
    this.logger.info("Calling: localStorage.getItem(this.authKey)" + " with authKey=" + this.authKey);
    const i = localStorage.getItem(this.authKey);
    if (i) {
      //this.logger.info(`Parsing ${i}`)
      return JSON.parse(i);
    }

    return null;
  }

  getToken(): string | undefined {
    this.logger.info(`calling this.getTokenResponse()`)
   
    const tokenResponse = this.getTokenResponse();
    this.logger.info(`got token`)
   
    if (tokenResponse && tokenResponse.token) {
      return tokenResponse.token;
    }

    this.logger.info(`token undefined`)
   
    return undefined;
  }

  getRefreshToken(): string | undefined {
    this.logger.info(`from getRefreshToken calling this.getTokenResponse()`)
    const tokenResponse = this.getTokenResponse();

    if (tokenResponse) {
        return tokenResponse.refreshToken;
      }

    this.logger.info(`token undefined`)
      
    return undefined;
  }


  refreshToken(): Observable<TokenResponse> {
    this.logger.info('in refreshToken()');    
    const url = localStorage.getItem('BASEURL') + 'Token/login';
    const refreshToken = this.getRefreshToken();
 
    if (!refreshToken) {
      //this.logout();
      this.logger.info('refreshToken null');    
     
      return new Observable<TokenResponse>();
    }

    this.logger.info('refreshToken not null');    
    this.logger.info(refreshToken);    

    const data = {
      // required when signing up with username/password
      Email: localStorage.getItem('EMAIL'),
      GrantType: 'refreshtoken',
      RefreshToken: refreshToken,
      // space-separated list of scopes for which the token is issued
      ClientId: 'GITT'
    };

    return this.getAuthFromServer(url, data);
  }

    
  // retrieve the access & refresh tokens from the server
  getAuthFromServer(url: string, data: any): Observable<TokenResponse> {
    this.logger.info('in getAuthFromServer');

return this.http.post<TokenResponse>(url, data).pipe(
      map((res: TokenResponse) => {
        this.endCall();

        if (res && res.succeeded && res.token) {
          if(res.succeeded)
          {
            this.setTokenResponse(res);
            this.setTokenData(res.token);
            this.logger.info('Done ' + url);
          } 
          else {
            this.clearTokenResponse();
            this.setTokenData(null);
            this.logger.info('Done where res.succeeded = false ' + url);

          }
        
          return res;
        } else {
          this.logger.info('Login failed, res.message = ' + res.message);
        }

        return res;
      }), // don't catch error
      finalize(() => { this.endCall();  })
    );
  }

  setTokenData(token: any)
  {
    const tokenData = this.decodeToken(token);
    this._tokenDataSubject.next(tokenData);
  }

  public decodeToken(token: string = '') {
    if (token === null || token === '') {
      return { upn: '' };
    }
    const parts = token.split('.');
    if (parts.length !== 3) {
      throw new Error('JWT must have 3 parts');
    }
    const decoded = this.urlBase64Decode(parts[1]);
    if (!decoded) {
      throw new Error('Cannot decode the token');
    }
    return JSON.parse(decoded);
  }
  
  private urlBase64Decode(str: string) {
    let output = str.replace(/-/g, '+').replace(/_/g, '/');
    switch (output.length % 4) {
      case 0:
        break;
      case 2:
        output += '==';
        break;
      case 3:
        output += '=';
        break;
      default:
        // tslint:disable-next-line:no-string-throw
        throw 'Illegal base64url string!';
    }
    return decodeURIComponent((<any>window).escape(window.atob(output)));
  }
}
