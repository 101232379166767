import { Injectable } from '@angular/core';
import {
  Observable,
  BehaviorSubject,
  empty
} from 'rxjs';
import { map, catchError, finalize } from 'rxjs/operators';
import { User, UsersWithCount } from './user';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ChangeRequestResponse } from '../shared/changeRequestResponse';
import { TokenResponse } from './tokenResponse';
import { BaseServiceService } from '../shared/base-service.service';
import { LogService } from '../logging/log.service';
import { SettingsService } from '../app-settings/settings.service';
import { TokenService } from './token.service';
//import { EmptyObservable } from 'rxjs/observable/EmptyObservable';

@Injectable({
  providedIn: 'root'
})
export class AuthService extends BaseServiceService {
  authKey = 'auth';

  // TODO: combine this?
  private _isLoggedInSubject = new BehaviorSubject<boolean>(false);
  public isLoggedIn$ = this._isLoggedInSubject.asObservable();
  public isLoggedIn = false;

  private _isAdimnSubject = new BehaviorSubject<boolean>(false);
  public isAdmin$ = this._isAdimnSubject.asObservable();
  public isAdmin = false;
  public hasSubscription = false;

  user: User = <User>{};
  //token: string;

  constructor(
    private http: HttpClient,
    settings: SettingsService,
    protected tokenService: TokenService,
    logger: LogService
  ) {
    super(logger, settings);
    this.logger.info('Constructor for AuthService.');

    logger.info('this.tokenService.tokenDataSubject$' + this.tokenService.tokenDataSubject$);

    this.tokenService.tokenDataSubject$.subscribe(tokenData => {
   
      const now = new Date().getTime();
      if(tokenData)
      {

      // Timezones?
      // token has expired or will withing 5 minutes.
      if (now > (Number(tokenData.RET) - 300) * 1000) {
        this.logger.info('Refresh token is expired, not logging in.');
        return;
      }

           this.user.userId = tokenData.UserId;
            this.user.email = tokenData.Email;
            localStorage.setItem('userId', tokenData.UserId);
  
        
            this.isLoggedIn = true;
            this._isLoggedInSubject.next(true);
          
  
            if (tokenData.IsAdmin === 'true') {
              this.isAdmin = true;
              this._isAdimnSubject.next(true);
            } else {
              this.isAdmin = false;
              this._isAdimnSubject.next(false);
          }
        } else {

          this.isLoggedIn = false;
         this._isLoggedInSubject.next(false);
         this._isAdimnSubject.next(false);
              this.isAdmin = false;
       
        }
          this.logger.info('Auto logging in.');
        });
 
  }

  sendConfirmationEmail(user: User): Observable<ChangeRequestResponse> {
    this.user.email = user.email;
    const url = this.settings.get('BASEURL') + 'User/ConfirmEmail';
    const data = {    
      clientId: "GITT",
      email: user.email
    };

    this.startingCall(url, data);

  
    return this.http.post<any>(url, data).pipe(
      catchError(err => this.handleError(err)),
          finalize(() => {this.endCall(); })
    );
  }

  setLogin(user: User) {
    this.user = user;
    this.isLoggedIn = true;
    this.isLoggedIn = true;
    this._isLoggedInSubject.next(true);

  }


  
  login(user: User): Observable<TokenResponse> {
    //  this.user.email = user.email;
    this.logout();
    // for the refresh token process
    localStorage.setItem("EMAIL", user.email);
    const url = this.settings.get('BASEURL') + 'Token/login';
    const data = {
      clientId: "GITT",
      email: user.email,
      Password: user.password,
      GrantType: "password"
    };
    this.user = user;
    
    this.startingCall(url, data);
    return this.tokenService.getAuthFromServer(url, data);

  }

  register(user: User): Observable<ChangeRequestResponse> {
    this.user.email = user.email;
    const url = this.settings.get('BASEURL') + 'user/register';
    const data = {    
      clientId: "GITT",
      email: user.email,
      password: user.password
    };

    this.startingCall(url, data);

  
    return this.http.post<any>(url, data).pipe(
      catchError(err => this.handleError(err)),
          finalize(() => {this.endCall(); this.clearProjectInfo(); })
    );
  }

  clearProjectInfo(): void {
    this.logger.info('Clearing project info');
    localStorage.removeItem('projectId');
    localStorage.removeItem('selectedProject');
  }

  changePassword(
    email: string,
    oldPassword: string,
    newPassword: string
  ): Observable<ChangeRequestResponse> {
    const url = this.settings.get('BASEURL') + 'user/changePassword';
    const data = {
      clientId: "GITT",
      email: email,
      oldPassword: oldPassword,
      newPassword: newPassword
    };

    this.startingCall(url, data);

    return this.http.post<ChangeRequestResponse>(url, data).pipe(
      catchError(err => this.handleError(err)),
      finalize(() => this.endCall())
    );
  }

  resetPassword(
    email: string,
    token: string,
    newPassword: string
  ): Observable<ChangeRequestResponse> {
    const url = this.settings.get('BASEURL') + 'user/resetPassword';
    const data = {
      clientId: "GITT",
      email: email,
      token: token,
      password: newPassword
    };

    this.startingCall(url, data);

    return this.http.post<ChangeRequestResponse>(url, data).pipe(
      catchError(err => this.handleError(err)),
      finalize(() => this.endCall())
    );
  }

  resetPasswordRequest(email: string): Observable<ChangeRequestResponse> {
    const url = this.settings.get('BASEURL') + 'user/resetPasswordRequest';
    const data = {
      clientId: "GITT",
      email: email
    };

    this.startingCall(url, data);

    return this.http.post<ChangeRequestResponse>(url, data).pipe(
      catchError(err => this.handleError(err)),
      finalize(() => this.endCall())
    );
  }


  

  resendConfirmation(email: string): Observable<ChangeRequestResponse> {
    const url = this.settings.get('BASEURL') + 'user/resendConfirmationEmail';
    const data = {
      clientId: "GITT",
      email: email
    };

    this.startingCall(url, data);

    return this.http.post<ChangeRequestResponse>(url, data).pipe(
      catchError(err => this.handleError(err)),
      finalize(() => this.endCall())
    );
  }


  clearAuth() {
      this.tokenService.clearTokenResponse();
      this.isLoggedIn = false;
      this._isLoggedInSubject.next(false);   
      this._isAdimnSubject.next(false);
      this.user.email = '';
      this.user.password = '';
      this._isLoggedInSubject.next(false);
      this.isAdmin = false;
      this.hasSubscription = false;
      this._isAdimnSubject.next(false);
      this.settings.logout();
      this.logger.info('Logging out.');
      localStorage.removeItem(this.authKey);
     
  }


  logout(): Observable<TokenResponse> {
   
    localStorage.setItem("target", '');
    this.clearAuth();
          return empty();
  }
  private getRandomIntInclusive(min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1) + min); //The maximum is inclusive and the minimum is inclusive
  }
 
  public confirmEmail(email: string, token: string) : Observable<ChangeRequestResponse> {
   
    const url = this.settings.get('BASEURL') + 'User/ConfirmEmail';
    
    this.startingCall(url, token);

    return this.http.get<ChangeRequestResponse>(url, { 
      params: new HttpParams()
      .set('email', email)
      .set('token', token)

    }).pipe(
      catchError(err => this.handleError(err)),
      finalize(() => this.endCall())
    );
 }

 
getUsers(sortColumn: string = 'userName',
  sortDirection: string = 'asc',
  pageNumber: number = 0,
  pageSize: number = 25,
 ): Observable<UsersWithCount> {
    const url = this.settings.get('BASEURL') + 'user/users';

    this.startingCall(url, null);

    return this.http.get<UsersWithCount>(url, {
        params: new HttpParams()
            .set('sortColumn', sortColumn)
            .set('sortDirection', sortDirection)
            .set('pageNumber', pageNumber.toString())
            .set('pageSize', pageSize.toString())
          
    }).pipe(
        catchError((err) => this.handleError(err) ),
        finalize(() => this.endCall())
    );
}

delete(userId: string): Observable<ChangeRequestResponse> {
  const url = this.settings.get('BASEURL') + 'user/Delete';
  const data = {
    clientId: "GITT",
    userId: userId
  };

  this.startingCall(url, data);

  return this.http.post<ChangeRequestResponse>(url, data).pipe(
    catchError(err => this.handleError(err)),
    finalize(() => this.endCall())
  );

}

}
