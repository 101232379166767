
    <h1>Privacy Policy</h1>
    <p>At <strong>Digital Tools, LLC</strong>, we value your privacy and are committed to being transparent about how we handle your information. Here’s everything you need to know:</p>

    <h2>Information We Collect</h2>
    <p>We collect only the minimal information necessary to provide you with account functionality:</p>
    <ul>
        <li><strong>Login Information:</strong> When you create an account, we collect your username and encrypted password to facilitate login and account management.</li>
    </ul>
    <p>We do not collect any other personal information beyond what is required for the login functionality.</p>

    <h2>How We Use Your Information</h2>
    <ul>
        <li>Your login information is used solely to provide access to your account and its associated features.</li>
        <li>We do not share, sell, or disclose your information to third parties.</li>
        <li>No data is collected for marketing or tracking purposes.</li>
    </ul>

    <h2>Cookies and Tracking</h2>
    <p>We do <strong>not</strong> use cookies or any tracking technologies on this site. Your activity is not monitored or tracked in any way.</p>

    <h2>Third-Party Services</h2>
    <p>If third-party services, such as payment processors (e.g., Stripe), are used, these services may collect and process information independently. Please review their privacy policies for more information.</p>

    <h2>Your Rights</h2>
    <ul>
        <li>You have the right to access and manage your account information.</li>
        <li>You can request deletion of your account and all associated data.</li>
    </ul>

    <h2>Security</h2>
    <p>We use industry-standard encryption methods to secure your login information. Your password is encrypted and cannot be accessed even by us.</p>

    <h2>Contact Us</h2>
    <p>If you have any questions about this privacy policy or how your information is handled, please contact us at:</p>
    <p><strong>Email:</strong> <a href="mailto:your-email@example.com">your-email&#64;example.com</a></p>
    <p><strong>Address:</strong> Your Business Address</p>
